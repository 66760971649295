import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { AppBar } from "../components";

const Terms = () => {
  return (
    <>
      <AppBar title="Ketentuan Layanan" isBack />
      <ScrollView contentContainerStyle={termsAndConditionStyles.container}>
      <View>
          <Text style={[termsAndConditionStyles.title]}>SYARAT DAN KETENTUAN PENGGUNA FAMMI </Text>
          <Text style={termsAndConditionStyles.text}>
          {"\n"}
Sebagai pengguna layanan Fammi, Anda diwajibkan untuk membaca Syarat dan Ketentuan yang terlampir dengan seksama sebelum menggunakan setiap fitur dan/atau layanan yang kami sediakan. 
{"\n\n"}
Dengan mengakses dan/atau menikmati Layanan Kami, Anda setuju bahwa Anda telah membaca, memahami, mengetahui, menerima, dan menyetujui seluruh informasi, syarat, dan ketentuan penggunaan Layanan yang terdapat dalam Ketentuan Penggunaan ini. 
{"\n\n"}
Ketentuan Penggunaan ini merupakan suatu perjanjian sah terkait tata cara dan persyaratan penggunaan Platform antara Anda dengan pengelola Platform yaitu PT Fammi Indonesia Edutech.
{"\n\n"}
Mohon diperhatikan bahwa Ketentuan Penggunaan dan Kebijakan Privasi dapat diperbarui dari waktu ke waktu. Anda tidak dipersilakan untuk mengakses Layanan apabila Anda tidak setuju terhadap Ketentuan Penggunaan ini (sebagaimana diperbarui dari waktu ke waktu). Kami berhak untuk sewaktu-waktu mengubah, menghapus dan/atau menerapkan syarat dan ketentuan baru dalam Ketentuan Penggunaan ini. Pemakaian Anda yang berkelanjutan terhadap Layanan akan dianggap sebagai persetujuan untuk tunduk kepada perubahan atas syarat dan ketentuan dari Ketentuan Penggunaan ini.
{"\n\n"}{"\n"}
          </Text>
          <Text style={[termsAndConditionStyles.title]}>KETENTUAN UMUM </Text>
          <Text style={termsAndConditionStyles.text}>
          {"\n"}
          Kami adalah PT. Fammi Indonesi Edutech suatu perusahaan yang bergerak di bidang penyediaan layanan konsultasi dan edukasi terkait permasalahan kesehatan mental dan keluarga. 
          {"\n\n"}
Layanan adalah layanan yang kami sediakan kepada Pengguna yaitu sebagai support system bagi para pengguna dalam menghadapi permasalahan yang dihadapi sehari-hari berkaitan dengan kesehatan mental dan keluarga. Layanan yang diberikan diantaranya konseling psikologi, berbagi pengalaman, teman curhat, forum diskusi, edukasi dan pelatihan (seminar, webinar atau workshop). 
{"\n\n"}
Pengguna atau Anda adalah setiap subjek hukum termasuk diantaranya adalah individu, badan usaha, dan/atau badan hukum yang menggunakan Layanan Kami. 
{"\n\n"}{"\n"}
          </Text>
          <Text style={[termsAndConditionStyles.title]}>LAYANAN  </Text>
          <Text style={termsAndConditionStyles.text}>
          {"\n"}
          Konsultasi Ahli: Pada Layanan ini Anda akan bertemu dengan seorang Ahli (Psikolog, Psikiater, atau Konselor) dan bisa mendapatkan solusi langsung dari ahlinya terkait permasalahan yang sedang Anda alami. 
          {"\n\n"}
Sharing Tokoh Ahli: Pada Layanan ini Anda akan bertemu dengan tooh (orangtua, guru/dosen, karyawan, mahasiswa) yang sudah berpengalaman menghadapi beragam permasalahan. Anda akan mendapatkan wawasan/pengetahuan terkait pengalaman orangtua tersebut dalam menghadapi masalah yang serupa. Para tokoh telah mengikuti pelatihan terkait dengan layanan dari Fammi.
{"\n\n"}
Cerita ke Sahabat Ahli: Pada Layanan ini Anda akan bertemu dengan teman curhat. Anda bisa mencurahkan perasaan dan pikiran Anda. Teman curhat akan menjadi pendengar yang baik dan memahami apa yang Anda rasakan. Para Sahabat telah mengikuti pelatihan terkait dengan layanan dari Fammi.
{"\n\n"}{"\n"}
          </Text>
          <Text style={[termsAndConditionStyles.title]}>TENTANG KOTA FAMMI</Text>
          <Text style={termsAndConditionStyles.text}>
          {"\n"}
          Kota Fammi adalah sebuah komunitas online yang didedikasikan untuk kesehatan mental dan dukungan emosional yang berada di dalam aplikasi Fammi. Dalam rangka memastikan pengalaman yang aman dan bermanfaat bagi semua anggota, berikut adalah ketentuan dan kebijakan yang berlaku dalam penggunaan platform Kota Fammi:
{"\n\n"}
<Text style={{fontWeight: "600"}}>Kode Etik </Text>
{"\n\n"}
a. Anggota Kota Fammi diharapkan untuk saling menghormati, mendukung, dan berinteraksi secara positif.
{"\n"}
b. Tidak diperkenankan melakukan tindakan yang dapat merugikan atau melanggar privasi anggota lainnya.
{"\n"}
c. Setiap bentuk perilaku yang tidak pantas, pelecehan, atau diskriminasi tidak akan ditoleransi.
{"\n"}
d. Seluruh Ahli Kota Fammi akan menjalankan kode etik sesuai dengan profesi yang dijalankan.
{"\n"}{"\n"}
<Text style={{fontWeight: "600"}}>Privasi dan Keamanan</Text>
{"\n"}{"\n"}
a. Informasi pribadi yang disampaikan oleh anggota akan dijaga kerahasiaannya sesuai dengan kebijakan privasi yang berlaku. 
{"\n"}
b. Setiap anggota diharapkan untuk menjaga kerahasiaan informasi pribadi yang diperoleh dari anggota lainnya. 
{"\n"}
c. Anggota dihimbau untuk tidak membagikan informasi pribadi atau rahasia kepada anggota lain atau pihak ketiga tanpa izin.
{"\n"}
d. Para Ahli, Tokoh Ahli dan Sahabat Ahli akan menjaga kerahasiaan informasi yang disampaikan oleh anggota. 
{"\n"}{"\n"}
<Text style={{fontWeight: "600"}}>Konten dan Komentar</Text>
{"\n"}{"\n"}
a. Anggota bertanggung jawab untuk tidak mengirimkan atau membagikan konten yang melanggar hukum, mengandung unsur kekerasan, SARA atau bersifat menghina. 
{"\n"}
b. Komentar/chat yang dibagikan harus sesuai dengan konteks, tidak mengandung spam, promosi yang tidak sah, atau berisi tautan yang meragukan. 
{"\n"}
c. Tim Kota Fammi berhak untuk menghapus konten atau komentar/chat yang melanggar ketentuan ini atau yang dianggap tidak pantas.
{"\n"}{"\n"}
<Text style={{fontWeight: "600"}}>Tanggung Jawab Pribadi</Text>
{"\n"}{"\n"}
a. Setiap anggota bertanggung jawab atas tindakan, keputusan, dan interaksi yang dilakukan di dalam platform Kota Fammi. 
{"\n"}
b. Kota Fammi tidak bertanggung jawab atas konsekuensi atau kerugian yang mungkin timbul akibat tindakan atau keputusan anggota.
{"\n"}{"\n"}
<Text style={{fontWeight: "600"}}>Bantuan Ahli</Text>
{"\n"}{"\n"}
a. Ahli yang terdaftar di Kota Fammi adalah profesional terkualifikasi di bidangnya masing-masing. 
{"\n"}
b. Anggota yang menggunakan layanan bantuan ahli bertanggung jawab untuk menggunakan informasi atau saran yang diberikan dengan bijak. 
{"\n"}
c. Efektivitas dari layanan yang diberikan oleh ahli dikembalikan pada kondisi masing-masing individu. 
{"\n"}{"\n"}
<Text style={{fontWeight: "600"}}>Waktu atau Durasi Layanan </Text>
{"\n"}{"\n"}
a. Setiap sesi curhat atau konseling dengan ahli memiliki durasi 30 menit dalam bentuk percakpaan via teks. Pastikan Anda memanfaatkan waktu sesi dengan efektif.
{"\n"}
b. Ketepatan waktu sangat dihargai. Usahakan untuk hadir tepat waktu dan memanfaatkan seluruh durasi sesi dengan baik.
{"\n"}{"\n"}
<Text style={{fontWeight: "600"}}>Reschedule </Text>
{"\n"}{"\n"}
a. Jika Anda perlu merubah jadwal sesi curhat atau konseling dengan ahli, Anda dapat melakukan reschedule dengan memberi tahu ahli maksimal 1 x 24 jam sebelum jadwal yang telah ditentukan. Jika ahli menyetujui maka bisa dilakukan perubahan jadwal. Jika tidak, anda boleh memilih ahli lain yang tersedia.
{"\n"}
b. Reschedule hanya boleh dilakukan satu kali. 
{"\n"}{"\n"}
<Text style={{fontWeight: "600"}}>Perubahan Jadwal Ahli</Text>
{"\n"}{"\n"}
a. Jika ahli memiliki kepentingan yang mendesak atau dalam keadaan sakit, ahli perlu melakukan perubahan jadwal sesi curhat atau konseling yang telah ditentukan. Ahli akan memberi tahu Anda secepat mungkin tentang perubahan tersebut.
{"\n"}
b. Jika terdapat perubahan jadwal dari ahli, Anda memiliki pilihan untuk menerima jadwal baru atau melakukan reschedule jika jadwal baru tidak sesuai.
{"\n"}{"\n"}
<Text style={{fontWeight: "600"}}>Perubahan Ketentuan dan Kebijakan</Text>
{"\n"}{"\n"}
Ketentuan dan kebijakan ini dapat mengalami perubahan sewaktu-waktu. Anggota akan diberitahu tentang perubahan tersebut melalui pemberitahuan di platform atau melalui email.
{"\n"}{"\n"}
<Text style={{fontWeight: "600"}}>Penutup</Text>
{"\n"}{"\n"}
a. Penggunaan platform Kota Fammi merupakan persetujuan terhadap ketentuan dan kebijakan yang berlaku. 
{"\n"}
b. Kota Fammi berhak untuk membatasi akses atau menghapus akun anggota yang melanggar ketentuan dan kebijakan yang ditetapkan.
          </Text>
        </View>
      </ScrollView>
    </>
  );
};

export const termsAndConditionStyles = StyleSheet.create({
  container: {
    backgroundColor: "#FFF",
    padding: 16,
  },
  title: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 16,
  },
  text: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 16,
  },
  list: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 4,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 100,
    backgroundColor: "#000",
    marginTop: 6,
  },
});

export default Terms;
