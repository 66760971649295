import React, { useEffect, useState } from "react";
import { Image, Pressable, StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { CopyIcon, ReceiptIcon } from "../assets/icons";
import Clipboard from "@react-native-clipboard/clipboard";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { emoney } from "../services/transactions";

const CopyToClipBoard = ({ label, setSnackBarMsg, setShowSnackBar,t }) => {
  const copyToClipboard = async (text) => {
    let copiedText = text;
    setSnackBarMsg(t("detail-order:success-copy-va"));
    if (text.indexOf("Rp") > -1) {
      copiedText = copiedText.replace("Rp ", "").replace(/\./g, "");
      setSnackBarMsg(t("detail-order:success-copy-total"));
    }
    Clipboard.setString(copiedText);
    setShowSnackBar(true);
  };
  return (
    <Pressable onPress={() => copyToClipboard(label)} style={styles.copy}>
      <CopyIcon />
      <Text style={styles.copyValue}>{label}</Text>
    </Pressable>
  );
};

const InvoiceCard = (props) => {
  const {data, setSnackBarMsg, setShowSnackBar, t} = props;
  const [note, setNote] = useState({});
  const [duration, setDuration] = useState("");
  const [timeDiff, setTimeDiff] = useState(100);
  const navigation = useNavigation();
  const ewallet = ["gopay", "shopeepay"];

  useEffect(() => {
    if (!data || !data?.note) {
      return;
    }

    setNote(JSON.parse(data?.note));
  }, [data]);

  useEffect(() => {
    const theTimeoutId = setTimeout(() => {
      if (timeDiff <= 0) {
        return;
      }

      const displayedDuration = [];
      const limitTime = moment(data?.timeLimit);
      const currentTime = moment();
      
      const theDiff = limitTime.diff(currentTime, "seconds");

      const hours = Math.floor(theDiff / 3600);
      const minutes = Math.floor((theDiff % 3600) / 60);
      const seconds = Math.floor((theDiff % 3600) % 60);

      if (hours > 0) {
        displayedDuration.push(hours);
      }

      if (minutes > 0) {
        displayedDuration.push(('0'+minutes).slice(-2));
      }

      if (seconds > 0) {
        displayedDuration.push(('0'+seconds).slice(-2));
      }

      setDuration(displayedDuration.join(":"))
      
      const newDiff = limitTime.diff(currentTime, "seconds");
      setTimeDiff(newDiff);
    }, 1000);

    if (timeDiff <= 0) {
      clearTimeout(theTimeoutId);
    }

  }, [timeDiff, data]);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={async () => {
        if (data?.timeLimit) {
          return navigation.navigate("DetailOrder", {
            transactionId: await AsyncStorage.getItem("0b806ea")
          });
        }

        if (note?.additionalContentId) {
          return navigation.navigate("DetailOrder", {
            transactionId: data?.transactionId
          });
        }

        return navigation.navigate("ConsultationForm", {
          transactionId: await AsyncStorage.getItem("0b806ea"),
          speakerId: note?.speakerId,
          consultationDate: note?.date,
          consultationHour: note?.time,
          consultationType: "chat"
        });
      }}>
        <View style={styles.header}>
          <View style={styles.title}>
            <ReceiptIcon />
            <Text style={styles.titleText}>Invoice</Text>
          </View>
          {
            data?.timeLimit &&
            <View style={styles.time}>
            {
              data?.timeLimit &&
              <Text style={styles.timeText}>{t("order:remaining")} {duration}</Text>
            }
            </View>
          }
          {
            !data?.timeLimit &&
            !note?.additionalContentId &&
            <View style={styles.time}>
              <Text style={styles.timeText}>{t("consultation_form:appbar_title_concised")}</Text>
            </View>
          }
        </View>
        <View style={{...styles.profile, width: "100%"}}>
          {
            note.speakerName &&
            <Image
              source={{
                uri: `https://fammi.ly/uploads/speakers/images/${note?.image}`,
              }}
              style={styles.profilePic}
            />
          }
          {
            note.additionalContentId &&
            <Image
              source={{
                uri: `https://fammi.ly/uploads/products/images/${data?.productImage}`,
              }}
              style={styles.profilePic}
            />
          }
          <View style={{width: "92%"}}>
            <Text style={styles.profileName}>
              {
                note.speakerName
              }
              {
                !note.speakerName &&
                data?.productName
              }
            </Text>
            <Text ellipsizeMode="tail" numberOfLines={1} style={{...styles.profileDesc, width: "100%", }}>
              {note.speakerDesc}
              {
                data?.productDesc
              }
            </Text>
          </View>
        </View>
        <View style={styles.bills}>
          {
            data?.vaNumber &&
            ewallet.indexOf(data?.paymentMethod) < 0 &&
            <View style={styles.billsInfo}>
              <Text style={styles.copyLabel}>{t("detail-order:va-number")}</Text>
              <CopyToClipBoard t={t} label={data?.vaNumber.replace(" 70012", "")} setSnackBarMsg={setSnackBarMsg} setShowSnackBar={setShowSnackBar}/>
            </View>
          }
          {
            data?.vaNumber &&
            ewallet.indexOf(data?.paymentMethod) > -1 &&
            <View style={styles.billsInfo}>
              <Text style={styles.copyLabel}>Bayar dengan {emoney[data?.paymentMethod]["name"]}</Text>
              <Text style={styles.copyValue}>Buka {emoney[data?.paymentMethod]["appName"]}</Text>
            </View>
          }
          <View style={styles.billsInfo}>
              <Text style={styles.copyLabel}>{t("invoice-card:total")}</Text>
              <CopyToClipBoard t={t} label={`Rp ${parseFloat(data.price).toLocaleString("ID-id")}`} setSnackBarMsg={setSnackBarMsg} setShowSnackBar={setShowSnackBar}/>
            </View>
        </View>
        <Image
          source={require("../assets/ticket-effect.svg")}
          style={{ width: "auto", height: 10, marginHorizontal: 10 }}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#fff",
  },
  header: {
    backgroundColor: "#2B0572",
    paddingVertical: 10,
    paddingLeft: 12,
    paddingRight: 9,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 11,
  },
  titleText: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#fff",
  },
  time: {
    backgroundColor: "#FF0000",
    borderRadius: 12,
    paddingVertical: 6,
    paddingHorizontal: 12,
  },
  timeText: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 11,
    color: "#fff",
  },
  profile: {
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 12,
    borderBottomColor: "#EAEAEA",
    borderBottomWidth: 1,
    borderStyle: "dashed",
  },
  profilePic: {
    width: 40,
    height: 40,
    borderRadius: 40 / 2,
  },
  profileName: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    marginBottom: 4,
  },
  profileDesc: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    color: "#999",
  },
  bills: {
    padding: 12,
    rowGap: 10,
  },
  billsInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  copy: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
  },
  copyLabel: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
    color: "#999",
  },
  copyValue: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#6724E2",
  },
});

export default InvoiceCard;
