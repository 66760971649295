import { StyleSheet, TouchableOpacity, Text } from "react-native";

function PrimaryButton(props) {
  const { title } = props;
  return (
    <TouchableOpacity style={styles.btnConsul} onPress={() => {
      if (props?.onPress) {
        props.onPress();
      }
    }}>
      <Text style={styles.txConsul}>{title}</Text>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  btnConsul: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    paddingTop: 7,
    paddingRight: 7,
    paddingBottom: 7,
    paddingLeft: 7,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
  },
  txConsul: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
  },
});
export default PrimaryButton;
