import Svg, { Path } from "react-native-svg";
const ChatIcon = ({ isActive, fill }) => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill={isActive || fill ? "#6724E2" : "#000"}
      d="M15.583 1.833H6.417c-2.53 0-4.584 2.044-4.584 4.565v6.398c0 2.521 2.054 4.565 4.584 4.565h1.375c.247 0 .577.165.733.367L9.9 19.552c.605.807 1.595.807 2.2 0l1.375-1.824a.93.93 0 0 1 .733-.367h1.375c2.53 0 4.584-2.044 4.584-4.565V6.398c0-2.52-2.054-4.565-4.584-4.565ZM7.333 11a.914.914 0 0 1-.916-.917.92.92 0 0 1 .916-.917.92.92 0 0 1 .917.917.914.914 0 0 1-.917.917ZM11 11a.913.913 0 0 1-.917-.917.92.92 0 0 1 .917-.917.92.92 0 0 1 .917.917A.913.913 0 0 1 11 11Zm3.667 0a.914.914 0 0 1-.917-.917.92.92 0 0 1 .917-.917.92.92 0 0 1 .916.917.914.914 0 0 1-.916.917Z"
    />
  </Svg>
);
export default ChatIcon;
