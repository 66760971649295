import React from "react";
import { Image, Linking, Pressable, Text, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";

export const Footer = ({ layout }) => {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <View style={styles("content")}>
        <Image
          source={require("../../assets/logo-graph.svg")}
          alt="fammi-logo"
          style={styles("footerImg")}
        />
        <Text style={styles("txFooter")}>
          Blok Alamanda 46 G-Land Katapang Residence 1, Bandung Jawa Barat
        </Text>
      </View>
      <View style={styles("content")}>
        <View style={[styles("content"), styles("socialMedia")]}>
          <Pressable
            onPress={() =>
              Linking.openURL("https://www.tiktok.com/@fammimenemani")
            }
          >
            <Image
              source={require("../../assets/tiktok.svg")}
              alt="tiktok"
              style={styles("footerImg")}
            />
          </Pressable>
          <Pressable
            onPress={() =>
              Linking.openURL("https://www.instagram.com/fammimenemani")
            }
          >
            <Image
              source={require("../../assets/instagram.svg")}
              alt="instagram"
              style={styles("footerImg")}
            />
          </Pressable>
          <Pressable
            onPress={() =>
              Linking.openURL(
                "https://www.linkedin.com/company/fammiindonesia/"
              )
            }
          >
            <Image
              source={require("../../assets/linkedin.svg")}
              alt="linkedin"
              style={styles("footerImg")}
            />
          </Pressable>
        </View>
        <Text style={styles("txFooter")}>@fammimenemani</Text>
      </View>
    </View>
  );
};

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      backgroundColor: "#F3F3F3",
      borderTopLeftRadius: 40,
      borderTopRightRadius: 40,
      paddingVertical: 30,
      paddingHorizontal: 40,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    content: { flexDirection: "row", alignItems: "center", gap: 14 },
    footerImg: {
      width: 30,
      height: 30,
    },
    txFooter: {
      fontFamily: "Archivo-Medium",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: 14,
      lineHeight: 15,
      textAlign: "center",
    },
  },
  {
    container: {
      flexDirection: "column",
      justifyContent: "center",
      paddingVertical: 20,
      paddingHorizontal: 20,
      gap: 20,
    },
    content: { flexDirection: "column", gap: 10 },
    socialMedia: { flexDirection: "row" },
    txFooter: {
      fontSize: 12,
      lineHeight: 13,
    },
  }
);
