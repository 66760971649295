import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import {
  ArrowLiteRight,
  DocumentTextIcon,
  PasswordCheckIcon,
  TickCircleIcon,
  WalletIcon,
} from "../assets/icons";
import { AppBar, PopupFactory } from "../components";
import InfoCircle from "../assets/icons/InfoCircle";
import TaskSquare from "../assets/icons/TaskSquare";
import DangerIcon from "../assets/icons/DangerIcon";
import { useNavigation } from "@react-navigation/native";
import { usePopup } from "../components/PopupFactory";

const ProductDetail = ({route}) => {
  const data = route.params;
  const [showWarning, setShowWarning] = useState(false);
  const navigation = useNavigation();
  const { toggle, visible } = usePopup();
  const { t } = useTranslation("product-detail");

  return (
    <React.Fragment>
      <AppBar title={t("appbar")} isBack />
      <ScrollView style={styles.container}>
        <View style={styles.contentBox}>
          <Image
            source={{
              uri: `https://fammi.ly/uploads/products/images/${data?.productImage}`,
            }}
            style={styles.image}
          />
          <View style={styles.content}>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 12,
              gap: 6
            }}>
              <InfoCircle/>
              <Text style={styles.title}>
                {
                  data?.productName
                }
              </Text>
            </View>
            <Text style={styles.desc}>
              {
                data?.productDescription
              }
            </Text>

            <View style={styles.list}>
              <View style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 12,
                gap: 6
              }}>
                <TaskSquare/>
                <Text style={{ ...styles.title, marginBottom: 2 }}>
                  {t("service-title")}
                </Text>
              </View>
              {
                data?.additionalNotes.split(",").map((a, index) => (
                  <View key={index} style={styles.service}>
                    <TickCircleIcon />
                    <Text style={styles.textList}>{a}</Text>
                    <Text>:</Text>
                    <Text style={styles.sessionText}>{data?.additionalDescriptions?.split(",")[index]}</Text>
                  </View> 
                ))
              }
            </View>
          </View>
          <View style={{ padding: 16 }}>
            <TouchableOpacity 
              style={styles.buyBtn}
              onPress={() => {
                setShowWarning(true);
              }}
            >
              <View style={{ ...styles.buyBtnBox, columnGap: 6 }}>
                <WalletIcon />
                <Text
                  style={{ ...styles.textList, color: "#fff", width: "auto" }}
                >
                  {t("price")} Rp
                  {
                    parseFloat(data?.productPrice).toLocaleString("ID-id")
                  }
                </Text>
              </View>
              <View style={{ ...styles.buyBtnBox, columnGap: 2 }}>
                <Text
                  style={{ ...styles.textList, color: "#fff", width: "auto" }}
                >
                  {t("button")}}
                </Text>
                <ArrowLiteRight />
              </View>
            </TouchableOpacity>
          </View>
        </View>
        {
          data?.tnc &&
          <View style={styles.fbBox}>
            <View style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "100%",
              marginBottom: 12,
              gap: 6,
            }}>
              <DocumentTextIcon fill="black"/>
              <Text style={{ ...styles.title, marginBottom: 2 }}>
                {t("terms-title")}
              </Text>
            </View>
            <View>
              {
                data?.tnc?.split("\n")?.map((d, index) => {
                  return <Text>
                    {index * 1 + 1}. {d}
                  </Text>
                })
              }
            </View>
          </View>
        }
        <Modal transparent={true} visible={showWarning}>
          <View style={styles.modalContainer}>
            <View style={styles.parentBox}>
              <DangerIcon/>
              <Text style={{
                marginTop: 12, 
                fontFamily: "Archivo-Regular",
                fontWeight: "700"
              }}>
                {t("popup-terms:title")}
              </Text>
              <Text style={{
                marginTop: 12, 
                fontFamily: "Archivo-Regular",
                fontWeight: "400",
                textAlign: "center"
              }}>
                {t("popup-terms:desc")}
              </Text>
              <View style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 24,
                gap: 12,
                padding: 6,
                paddingBottom: 0,
                width: "100%",
              }}>
                <TouchableOpacity style={{
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: "#EAEAEA",
                  paddingTop: 12,
                  paddingBottom: 12,
                  alignItems: "center",
                  width: "50%"
                }}
                  onPress={() => {
                    setShowWarning(false);
                  }}
                >
                  <Text style={{
                    fontFamily: "Archivo-Regular",
                    fontWeight: "700"
                  }}>
                    {t("popup-terms:btn1")}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity style={{
                  backgroundColor: "#6724E2",
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: "#6724E2",
                  paddingTop: 12,
                  paddingBottom: 12,
                  alignItems: "center",
                  width: "50%"
                }}
                  onPress={() => {
                    setShowWarning(false);
                    navigation.navigate("PlaceOrder", {
                      product:data
                    });
                  }}>
                  <Text style={{
                    fontFamily: "Archivo-Regular",
                    fontWeight: "700",
                    color: "#FFF"
                  }}>
                    {t("popup-terms:btn2")}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
      {/* <PopupFactory
        type={"terms"}
        visible={visible}
        onCancel={toggle}
        onSubmit={() => {
          toggle();
          alert("Buy");
        }}
      /> */}
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 100,
    borderRadius: 10,
    margin: 16,
  },
  contentBox: {
    backgroundColor: "#fff",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  content: {
    paddingHorizontal: 16,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#eaeaea",
  },
  title: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 15,
  },
  desc: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 13,
    lineHeight: 16,
  },
  list: {
    flexDirection: "column",
    rowGap: 10,
    marginTop: 12,
  },
  service: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 8,
  },
  textList: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    width: "50%",
  },
  sessionText: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    marginLeft: 5,
    color: "#6724E2",
  },
  buyBtn: {
    backgroundColor: "#6724E2",
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingVertical: 9,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buyBtnBox: {
    flexDirection: "row",
    alignItems: "center",
  },
  fbBox: {
    margin: 16,
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingVertical: 24,
    alignItems: "center",
  },
  fbBoxIcon: {
    width: 60,
    height: 60,
    padding: 18,
    backgroundColor: "#E2DCFC",
    borderRadius: 60 / 2,
    marginBottom: 24,
  },
  parentBox: {
    fontSize: 14,
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingTop: 24,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 12,
    alignItems: "center",
    justifyContent: "center"
  },
  modalContainer: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    padding: 16,
  },
  modalOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
});

export default ProductDetail;
