import * as React from "react";
import Svg, { Path } from "react-native-svg";
const DocumentTextIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill={props?.fill ?? "none"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M15.75 5.25V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V5.25C2.25 3 3.375 1.5 6 1.5H12C14.625 1.5 15.75 3 15.75 5.25Z"
      stroke={props?.fill === "black" ? "white" : "black"}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.875 3.375V4.875C10.875 5.7 11.55 6.375 12.375 6.375H13.875M6 9.75H9M6 12.75H12"
      stroke={props?.fill === "black" ? "white" : "black"}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default DocumentTextIcon;
