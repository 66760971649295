import React from "react";
import { ImageBackground, Text, View, Image } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";

export const Mission = ({ layout }) => {
  const styles = responsiveStyles(layout);
  const image = layout.width < 500 ? "mob" : "web";
  return (
    <ImageBackground
      source={require("../../assets/images/mission-bg.png")}
      resizeMode="repeat"
      style={styles("container")}
      imageStyle={{ borderRadius: 40 }}
    >
      <View style={styles("content")}>
        <Text style={styles("txMission")}>
        Telah menjangkau dan memberikan manfaat ke lebih dari 4.000 sekolah, Fammi siap mendampingi lembaga Anda merancang kebijakan yang sesuai melalui laporan yang lengkap dan saran yang praktis.
        </Text>
        <Image
          source={require(`../../assets/images/mission-brands-${image}.png`)}
          alt="mission-brands"
          style={styles("brands")}
          resizeMode="cover"
        />
      </View>
      <Image
        source={require(`../../assets/images/mission-image-${image}.png`)}
        alt="mission-image"
        resizeMode="cover"
        style={styles("image")}
      />
    </ImageBackground>
  );
};

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      borderWidth: 1,
      borderColor: "#EAEAEA",
      borderRadius: 40,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    content: { gap: 20, paddingLeft: 40, paddingVertical: 0 },
    brands: { width: 410, height: 180 },
    txMission: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: 20,
      lineHeight: 30,
      maxWidth: 436,
      marginTop: 20
    },
    image: {
      width: "100%",
      height: 440,
      maxWidth: "50%",
      borderTopRightRadius: 40,
      borderBottomRightRadius: 40,
    },
  },
  {
    container: {
      flexDirection: "column",
      gap: 60,
    },
    content: { gap: 30, paddingLeft: 20, paddingBottom: 0 },
    brands: { width: 300, height: 110 },
    txMission: {
      maxWidth: 312,
    },
    image: {
      width: "100%",
      height: 440,
      maxWidth: "100%",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 40,
      borderBottomLeftRadius: 40,
    },
  }
);
