import React, { Fragment, useMemo } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { MoreIcon } from "../assets/icons";
import moment from "moment/moment";
import groupBy from "../Utils/groupArrayBy";
import AsyncStorage from "@react-native-async-storage/async-storage";

function ChatList(props) {
  const { chats, senderId, setShowOptionChat, consId } = props;

  const chatsMapper = useMemo(() => {
    let filteredChat = chats?.filter((c)=>c.id.split("-")[0] === consId);
    const chatByTimeStamps = groupBy(
      filteredChat
        .map((d) => ({
          ...d,
          date: moment(d?.timestamps).format("YYYY-MM-DD"),
          replyMessage: d?.repliedMessage
            ? d?.repliedMessage
            : null,
        }))
        .sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        }),
      "date"
    );

    return chatByTimeStamps;
  }, [groupBy, chats]);
  const LabelTime = ({ time }) => (
    <View style={styles.parentLabel}>
      <View style={styles.labelItem}>
        <Text style={styles.textLabel}>
          {moment(time).format("dddd, DD MMM YYYY")}
        </Text>
      </View>
    </View>
  );
  const MyBubble = ({ data }) => (
    <View style={styles.parentMyBubble}>
      <View>
        <Pressable
          onPress={async () => {
            setShowOptionChat({
              msgId: data?.id,
              message: data?.message,
              time: data?.timestamps,
              consId: consId
            });
          }}
        >
          <MoreIcon />
        </Pressable>
        <Text style={styles.textTime}>
          {moment(data?.timestamps).format("HH:mm")}
        </Text>
      </View>
      <View style={{ marginLeft: 6, maxWidth: "80%" }}>
        {data?.replyMessage && (
          <View style={styles.repliedBubble}>
            <Text
              style={{ ...styles.textReplied, textAlign: "right" }}
              numberOfLines={1}
            >
              {data?.replyMessage}
            </Text>
          </View>
        )}
        <View
          style={[
            styles.myBubble,
            data?.replyMessage && {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 10,
            },
          ]}
        >
          <Text style={styles.textMyBubble}>{data?.message}</Text>
        </View>
      </View>
    </View>
  );
  const ReplyBubble = ({ data }) => (
    <View style={styles.parentReplyBubble}>
      <View style={{ marginRight: 6, maxWidth: "80%" }}>
        {data?.replyMessage && (
          <View style={styles.repliedBubble}>
            <Text
              style={{ ...styles.textReplied, textAlign: "left" }}
              numberOfLines={1}
            >
              {data?.replyMessage}
            </Text>
          </View>
        )}
        <View
          style={[
            styles.replyBubble,
            data?.replyMessage && {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 10,
            },
          ]}
        >
          <Text style={styles.textReplyBubble}>{data?.message}</Text>
        </View>
      </View>
      <View>
        <Pressable
          onPress={() => {
            setShowOptionChat({
              msgId: data?.id,
              message: data?.message,
              time: data?.timestamps,
              consId: consId
            });
          }}
        >
          <MoreIcon />
        </Pressable>
        <Text style={styles.textTime}>
          {moment(data?.timestamps).format("HH:mm")}
        </Text>
      </View>
    </View>
  );
  return (
    <View>
      {Object.keys(chatsMapper).map((item, index) => (
        <View key={index}>
          <LabelTime time={item} />
          {chatsMapper[item]?.map((chat, indexed) => (
            <Fragment key={indexed}>
              {senderId.toLowerCase() === chat.senderId.toLowerCase() ? (
                <MyBubble data={chat} />
              ) : (
                <ReplyBubble data={chat} />
              )}
            </Fragment>
          ))}
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  parentLabel: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    marginBottom: 20,
  },
  labelItem: {
    backgroundColor: "#E2DCFC",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    paddingTop: 8,
    paddingRight: 10,
    paddingBottom: 8,
    paddingLeft: 10,
  },
  textLabel: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 10,
    lineHeight: 11,
    textAlign: "center",
    color: "#9E8DBD",
  },
  parentMyBubble: {
    flexDirection: "row",
    alignItems: "flex-end",
    width: "100%",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  parentReplyBubble: {
    flexDirection: "row",
    alignItems: "flex-end",
    width: "100%",
    marginBottom: 20,
  },
  textTime: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 10,
    lineHeight: 11,
    color: "#999999",
  },
  myBubble: {
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 10,
    padding: 10,
  },
  replyBubble: {
    backgroundColor: "#fff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 0,
    padding: 10,
  },
  textMyBubble: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    textAlign: "right",
    color: "#FFFFFF",
  },
  textReplyBubble: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    textAlign: "left",
    color: "#000000",
  },
  repliedBubble: {
    backgroundColor: "#E2DCFC",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    padding: 10,
  },
  textReplied: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    color: "#9E8DBD",
  },
});
export default ChatList;
