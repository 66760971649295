import { Image, StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { HeartCircle, UserGroup } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import { getTarget } from "../services/chat";
import { useState } from "react";
import moment from "moment";
import { getSpeaker } from "../services/speakers";

function ListChat(props) {
  const { isLast, data, setOpenFeedback, setChosenChat, newUnreadChat, setNewUnreadChat, toggle, setConsType } = props;
  const [defaultImage, setDefaultImage] = useState(false);

  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={async () => {
        if (!data?.consultationReady) {
          return getSpeaker(data?.speakerId)
          .then((r) => {
            const speakerType = r?.data[0].speakerType;
            const type = speakerType === "expert" ? "Konsultasi" : speakerType === "figure" ? "Sharing" : "Curhat";
            setConsType({
              type: type,
              schedule: moment(data?.consultationDate).format("DD MMMM YYYY")+" Jam " + data?.consultationHour,
              consId: data?.consId,
              titleMsg: type+" Belum Dimulai",
              msg: type+ " akan dimulai pada tanggal "+moment(data?.consultationDate).format("DD MMMM YYYY")+" Jam " + data?.consultationHour+ " WIB"
            });
            toggle();
          });
        }

        if (data?.isDone && !data?.feedbackId) {
          setChosenChat({
            ...data
          });
          return setOpenFeedback(true);
        }

        getTarget(data?.target)
        .then((res) => {
          const target = res?.data.memberId;
          const reNewUnreadChat = newUnreadChat.filter((c) => c !== target);
          setNewUnreadChat(reNewUnreadChat);

          navigation.navigate("DetailChat", {
            ...data,
            speakerMemberId: target
          });
        });
      }}
      style={[
        styles.listChat,
        isLast && {
          borderBottomColor: "#eaeaea",
          borderBottomWidth: 1,
        },
      ]}
    >
      <View style={styles.flexed}>
        {
          (!data?.photo || data?.photo === "" || defaultImage) &&
          <View style={{marginRight: 12}}>
            <UserGroup/>
          </View>
        }
        {
          (!defaultImage && data?.photo && data?.photo !== "") &&
          <Image
            source={{ 
              uri: data?.loggedInMemberId === data?.memberId && data?.photo ? 
                  `https://fammi.ly/uploads/speakers/images/${data?.photo}` :
                  (data?.loggedInMemberId !== data?.memberId && data?.photo ? 
                  `https://fammi.ly/uploads/members/images/${data?.photo}` :
                  `https://via.placeholder.com/40?mode=${data?.mode}`) }}
            height={40}
            width={40}
            style={styles.avatar}
            onError={()=>setDefaultImage(true)}
          />
        }
        <View>
          <Text style={styles.textTitle}>{data?.title}</Text>
          <Text style={{ ...styles.textSpan, marginTop: 6 }}>{data.name}</Text>
        </View>
      </View>
      <View style={{ flexDirection: "column", alignItems: "flex-end" }}>
        <Text style={{ ...styles.textSpan, marginBottom: 6 }}>
          {data?.time}
        </Text>
        {data?.isDone &&
        !data?.feedbackId &&
        (
          <TouchableOpacity
            onPress={() => {
              setChosenChat(data);
              setOpenFeedback(true);
            }}
          >
            <HeartCircle />
          </TouchableOpacity>
        )
        }
        {
          (!data.isRead ||
            (
              data?.memberId === data?.loggedInMemberId &&
              newUnreadChat.indexOf(data?.speakerMemberId) > -1
            ) ||
            (
              data?.memberId !== data?.loggedInMemberId &&
              newUnreadChat.indexOf(data?.memberId) > -1
            )
          ) &&
          <View style={styles.indicator} />
        }
      </View>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  listChat: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    borderStyle: "solid",
    borderTopColor: "#eaeaea",
    borderTopWidth: 1,
  },
  flexed: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    height: 40,
    width: 40,
    borderRadius: 40 / 2,
    marginRight: 12,
  },
  textTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#000000",
  },
  textSpan: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    color: "#999999",
  },
  indicator: {
    backgroundColor: "#6724E2",
    height: 15,
    width: 15,
    borderRadius: 15 / 2,
  },
});

export default ListChat;
