import React from "react";
import {Image, Linking, Pressable, Text, View} from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";

export const Hero = ({ layout }) => {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("hero")}>
      <View style={styles("heroLeft")}>
        <Image
          source={require("../../assets/logo-type.svg")}
          alt="fammi-logo"
          style={styles("fammiLogo")}
        />
        <Text style={styles("title")}>
        Konsultan Pendidikan 
dan Kesehatan Mental 
untuk Sekolah & Lembaga
        </Text>
        <Text style={styles("subTitle")}>
        Menyediakan Layanan Asesmen dan Pendampingan 
        untuk Pimpinan, Guru, Karyawan dan Keluarga
        </Text>
        <View
          style={{ flexDirection: "row", alignItems: "center", columnGap: 12 }}
        >
          <Pressable onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=ly.fammi.kota')}>
            <Image
              source={require("../../assets/playstore.svg")}
              alt="play-store"
              style={styles("playStore")}
            />
          </Pressable>
          {/* <Image
            source={require("../../assets/images/app-store.png")}
            alt="app-store"
            style={styles("appStore")}
          /> */}
        </View>
      </View>
      <Image
        source={require("../../assets/images/hero-landing.png")}
        alt="hero-image"
        style={styles("heroImg")}
      />
    </View>
  );
};

const responsiveStyles = CreateResponsiveStyle(
  {
    hero: {
      flexDirection: "row",
      alignItems: "flex-start",
      backgroundColor: "#EEEAFF",
      borderRadius: 40,
      justifyContent: "space-between",
    },
    heroLeft: {
      paddingTop: 50,
      paddingLeft: 40,
      paddingBottom: 70,
    },
    fammiLogo: { width: 128, height: 50 },
    heroImg: {
      width: 400,
      height: 400,
      margin: 40
    },
    title: {
      fontFamily: "Archivo-Bold",
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: 40,
      lineHeight: 50,
      marginTop: 50,
      marginBottom: 20,
      maxWidth: 590,
    },
    subTitle: {
      fontFamily: "Archivo-Medium",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: 20,
      lineHeight: 28,
      marginBottom: 30,
      maxWidth: 590,
    },
    playStore: { width: 169, height: 50 },
    appStore: { width: 175, height: 50 },
  },
  {
    hero: {
      flexDirection: "column",
    },
    heroLeft: {
      paddingTop: 40,
      paddingLeft: 20,
      paddingBottom: 30,
    },
    fammiLogo: { width: 102, height: 40 },
    heroImg: {
      width: 332,
      height: 332,
      marginLeft: 20,
      marginBottom: 40,
      alignSelf: "center",
    },
    title: {
      maxWidth: 312,
      fontSize: 30,
      lineHeight: 40,
      marginTop: 40,
    },
    subTitle: {
      maxWidth: 312,
      fontSize: 16,
      lineHeight: 24,
      marginBottom: 20,
    },
    playStore: { width: 135, height: 40 },
    appStore: { width: 140, height: 40 },
  }
);
