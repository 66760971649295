import React, { useEffect, useState } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Modal from "react-native-modal";
import { ArrowLiteRight, CloseIcon, HeartCircle, HeartIcon, Profile2UserIcon } from "../assets/icons";
import { AppBar, FeedbackLike, ModalFeedback } from "../components";
import { checkFeedback, getFeedbackSummary } from "../services/feedback";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import ArrowRight from "../assets/icons/ArrowRight";
import { useTranslation } from "react-i18next";

const ResumeFeedbackCard = ({ Icon, label, value }) => {
  return (
    <View style={styles.resumeCard}>
      <View style={styles.resumeBox}>
        <View
          style={{
            ...styles.resumeBoxIcon,
            backgroundColor: label?.toLowerCase() === "kepuasan" || label?.toLowerCase() === "satisfaction" ? "#FFDBDB" : "#EAEAEA",
          }}
        >
          <Icon width={24} height={24} />
        </View>
        <Text style={styles.resumeLabelText}>{label}</Text>
      </View>
      <View style={styles.resumeBoxDivider} />
      <View style={{ ...styles.resumeBox, justifyContent: "center" }}>
        <Text style={styles.resumeValueText}>{value}</Text>
      </View>
    </View>
  );
};

const SpeakerFeedback = ({route}) => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [successFeedback, setSuccesssFeedback] = useState(false);
  const [consIds, setConsIds] = useState([]);
  const [summary, setSummary] = useState({});
  const [modalSpeakerVisible, setModalSpeakerVisible] = useState(false);
  const [chosenChat, setChosenChat] = useState({});
  const {params} = route;
  const {t} = useTranslation();
  useEffect(() => {
    getFeedbackSummary(params.speaker.speakerId)
    .then((res) => {
      setSummary(res.data[0]);
    });

    async function getUser() {
      return await AsyncStorage.getItem("token");
    }

    getUser()
    .then((res) => {
      checkFeedback(params.speaker.speakerId, res)
      .then((res) => {
        setConsIds(res?.data);
      });
    });
  }, [params.speakerId, successFeedback]);
  return (
    <React.Fragment>
      <AppBar title={t("feedback-detail:title")} isBack />
      <ScrollView style={styles.container}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>{t("feedback-detail:resume")}</Text>
          <View style={styles.resume}>
            <ResumeFeedbackCard
              Icon={HeartCircle}
              label={t("feedback-detail:satisfaction")}
              value={`${summary?.rate * 100 / 5 } %`}
            />
            <ResumeFeedbackCard
              Icon={Profile2UserIcon}
              label={t("feedback-detail:helped")}
              value={`${summary?.descriptions}`}
            />
          </View>
        </View>

          {
            consIds.length > 0 &&
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>{t("feedback-detail:add-review")}</Text>
                <View style={styles.addFeedback}>
                  <Text style={styles.normalText}>{t("feedback-detail:did-consult")}</Text>
                  <TouchableOpacity onPress={() => setModalSpeakerVisible(true)}>
                    <Text style={styles.addFeedbackBtn}>{t("feedback-detail:give")}</Text>
                  </TouchableOpacity>
                </View>
            </View>
          }
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>{t("feedback-detail:user-review")}</Text>
          <View style={{ rowGap: 12 }}>
            {
              params.feedback.map((f) => (
                <View key={f.feedbackId} style={styles.fbCard}>
                  <View style={styles.fbCardTop}>
                    <FeedbackLike totalLike={f.rate} />
                    <Text style={styles.fbCardDate}>{moment(f.created).format("DD MMM YYYY")}</Text>
                  </View>
                  <View style={{ padding: 12 }}>
                    <Text style={styles.normalText}>{f.descriptions}</Text>
                  </View>
                </View>
              ))
            }
          </View>
        </View>
      </ScrollView>
      <Modal isVisible={modalSpeakerVisible} onBackdropPress={() => {
        setModalSpeakerVisible(false);
      }}>
          <View style={{width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
            padding: 12,
            }}>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
              <View style={{
                flexDirection: "row", 
                alignItems: "center",
                alignItems: "center",
                gap: 6,
                fontFamily: "Archivo-Bold",
                fontSize: 12
              }}>
                <HeartIcon />
                <Text>{t("feedback-detail:choose-session")}</Text>
              </View>
              <CloseIcon/>
            </View>  
            <View style={{
              marginTop: '12px'
            }}>
              {
                consIds?.map((c) => {
                  return <TouchableOpacity onPress={() => {
                    setChosenChat(c);
                    setModalSpeakerVisible(false);
                    setOpenFeedback(true);
                  }}>
                    <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}>
                    <View style={{flexDirection: "row", alignItems: "center", gap: 12}}>
                        <Image
                          source={{ 
                            uri: `https://fammi.ly/uploads/speakers/images/${c?.speakerImage}`
                          }}
                          style={{width: 40, height: 40, borderRadius: 20}}
                      />
                        <View>
                          <Text style={{fontFamily: "Archivo-Regular", fontWeight: "600", fontSize: 12}}>
                            {c.summary}
                          </Text>
                          <Text style={{fontFamily: "Archivo-Regular", fontWeight: "400", fontSize: 12, color: "#999"}}>
                            {c.speakerName}
                          </Text>
                        </View>
                      </View>
                      <ArrowRight/>
                    </View>
                  </TouchableOpacity>
                })
              }
            </View>
          </View>
        </Modal>
      <ModalFeedback
        data={chosenChat}
        visible={openFeedback}
        hideDialog={() => {
          setOpenFeedback(false);
        }}
        setSuccess={setSuccesssFeedback}
      />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  section: { marginBottom: 16 },
  sectionTitle: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 15,
    marginBottom: 10,
  },
  resume: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 10,
  },
  resumeCard: {
    flexDirection: "column",
    flex: 1,
  },
  resumeBox: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 10,
    height: 60,
  },
  resumeBoxIcon: {
    padding: 8,
    borderRadius: 6,
  },
  resumeBoxDivider: {
    borderWidth: 1,
    borderColor: "#eaeaea",
    marginHorizontal: 10,
  },
  resumeLabelText: {
    textTransform: "capitalize",
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
  },
  resumeValueText: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 24,
    lineHeight: 26,
    textAlign: "center",
  },
  addFeedback: {
    padding: 12,
    backgroundColor: "#fff",
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addFeedbackBtn: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#6724E2",
  },
  normalText: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
  },
  fbCard: {
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  fbCardTop: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
    borderBottomColor: "#eaeaea",
    borderBottomWidth: 1,
  },
  fbCardDate: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 11,
    color: "#999",
  },
});

export default SpeakerFeedback;
