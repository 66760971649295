import Svg, { Path } from "react-native-svg";
const Calendar = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#000"
      d="M12.563 2.67V1.5A.567.567 0 0 0 12 .937a.567.567 0 0 0-.563.563v1.125H6.564V1.5A.567.567 0 0 0 6 .937a.567.567 0 0 0-.563.563v1.17C3.413 2.858 2.43 4.065 2.28 5.858a.377.377 0 0 0 .375.397h12.69c.217 0 .397-.188.375-.397-.15-1.793-1.133-3-3.158-3.188ZM15 7.38H3a.752.752 0 0 0-.75.75v4.62C2.25 15 3.375 16.5 6 16.5h6c2.625 0 3.75-1.5 3.75-3.75V8.13a.752.752 0 0 0-.75-.75Zm-8.093 6.278a.866.866 0 0 1-.247.157.747.747 0 0 1-.285.06.747.747 0 0 1-.285-.06.866.866 0 0 1-.247-.157.789.789 0 0 1-.218-.533c0-.195.082-.39.218-.533a.866.866 0 0 1 .247-.157.75.75 0 0 1 .57 0c.09.037.172.09.247.157a.789.789 0 0 1 .218.533c0 .195-.082.39-.218.533Zm.158-2.873a.866.866 0 0 1-.158.248.866.866 0 0 1-.247.157.747.747 0 0 1-.285.06.747.747 0 0 1-.285-.06.866.866 0 0 1-.247-.157.866.866 0 0 1-.158-.248.746.746 0 0 1-.06-.285c0-.098.022-.195.06-.285a.866.866 0 0 1 .158-.248.866.866 0 0 1 .247-.157.75.75 0 0 1 .57 0c.09.037.172.09.247.157.068.075.12.158.158.248.037.09.06.188.06.285a.747.747 0 0 1-.06.285Zm2.468.248a.866.866 0 0 1-.248.157.747.747 0 0 1-.285.06.747.747 0 0 1-.285-.06.866.866 0 0 1-.248-.157.789.789 0 0 1-.217-.533c0-.195.082-.39.217-.533a.866.866 0 0 1 .248-.157.687.687 0 0 1 .57 0c.09.037.172.09.248.157a.789.789 0 0 1 .217.533c0 .195-.082.39-.217.533Z"
    />
  </Svg>
);
export default Calendar;
