import React, { useMemo, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { EmptyState } from "..";
import { ChatIcon, DangerIcon, StarMoveIcon } from "../../assets/icons";
import TipsContent from "./TipsContent";

const PopupFactory = ({ type, visible, onCancel, onSubmit, titleMsg, msg }) => {
  const renderContent = useMemo(() => {
    switch (type) {
      case "updates":
        return (
          <EmptyState
            Icon={StarMoveIcon}
            title="Yuk Update Aplikasi Fammi"
            desc="Dapatkan fitur terbaru dan manfaat lainnya dengan memperbarui aplikasi Fammi"
          />
        );

      case "not-started":
        return (
          <EmptyState
            Icon={ChatIcon}
            title={titleMsg}
            desc={msg}
          />
        );

      case "terms":
        return (
          <EmptyState
            Icon={DangerIcon}
            title="Sudah membaca syarat ketentuan?"
            desc="Dengan membeli paket ini berarti kamu sudah membaca syarat ketentuan"
          />
        );

      case "tips":
        return <TipsContent />;
    }
  }, [type, msg, titleMsg]);

  const renderButton = useMemo(() => {
    switch (type) {
      case "updates":
        return {
          l1: "Nanti Aja",
          l2: "Update Sekarang",
          onSubmit,
        };
      case "not-started":
        return {
          l1: "Lihat Catatan",
          l2: "Oke",
          onSubmit,
        };
      case "terms":
        return {
          l1: "Baca Dulu",
          l2: "Lanjut Beli",
          onSubmit,
        };
      case "tips":
        return {
          l2: "Ok",
          onSubmit,
        };
    }
  }, [type, onSubmit]);

  return (
    <Modal isVisible={visible} onBackdropPress={onCancel}>
      <View style={[styles.container, type === "tips" && { padding: 0 }]}>
        {renderContent}
        <View
          style={[
            styles.buttons,
            type === "tips" && { padding: 12, marginTop: 0 },
          ]}
        >
          {
            renderButton.l1 &&
            <TouchableOpacity
              onPress={onCancel}
              style={[
                styles.button,
                {
                  backgroundColor: "#fff",
                  borderWidth: 1,
                  borderColor: "#eaeaea",
                },
              ]}
            >
              <Text style={styles.txButton}>{renderButton.l1}</Text>
            </TouchableOpacity>
          }
          <TouchableOpacity
            onPress={renderButton.onSubmit}
            style={[styles.button, { backgroundColor: "#6724E2" }]}
          >
            <Text style={[styles.txButton, { color: "#fff" }]}>
              {renderButton.l2}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    minHeight: 230,
    padding: 12,
    borderRadius: 10,
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 12,
    marginTop: 24,
  },
  button: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 11,
    borderRadius: 10,
  },
  txButton: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
  },
});

export const usePopup = () => {
  const [visible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!visible);
  };

  return {
    visible,
    toggle,
  };
};

export default PopupFactory;
