import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  View,
  StyleSheet,
  ScrollView,
  Image,
  Text,
  Dimensions,
  Pressable,
  TouchableOpacity
} from "react-native";
import { AppBar, ListExpert } from "../components";
import PopupFactory, { usePopup } from "../components/PopupFactory";
import { getConsultants } from "../services/consultants";
import {
  CategoryIcon,
  EmojiHappyIcon,
  HeartTickIcon,
  MessengerFillIcon,
} from "../assets/icons";
import AsyncStorage from "@react-native-async-storage/async-storage";

function ChooseExpert({ route }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [active, setActive] = useState(route.params?.type);
  const [type, setType] = useState(route.params?.type);
  const [currentSpeakerId, setCurrentSpeakerId] = useState("");
  const status = route.params?.status ? route.params?.status : "general";
  const hideHeader = route.params?.hideHeader;
  const { toggle, visible } = usePopup();
  const menu = [
    {
      id: "all",
      text: t("choose-expert:all"),
      sub: t("choose-expert:services"),
      icon: (fill) => {
        return <CategoryIcon fill={fill} />;
      },
    },
    {
      id: "expert",
      text: t("choose-expert:consult"),
      sub: t("choose-expert:expert"),
      icon: (fill) => {
        return <MessengerFillIcon fill={fill} />;
      },
    },
    {
      id: "figure",
      text: t("choose-expert:sharing"),
      sub: t("choose-expert:figure"),
      icon: (fill) => {
        return <HeartTickIcon fill={fill} />;
      },
    },
    {
      id: "partner",
      text: t("choose-expert:confide"),
      sub: t("choose-expert:partner"),
      icon: (fill) => {
        return <EmojiHappyIcon fill={fill} />;
      },
    },
  ];

  useEffect(() => {
    async function getCurrentSpeakerId() {
      const speakerId = await AsyncStorage.getItem("792f3e1")
      setCurrentSpeakerId(speakerId);
    }
    getCurrentSpeakerId();
    getConsultants(type ?? "all").then((res) => {
      setData(res?.data);
    });
  }, [type]);

  useEffect(() => {
    async function getDontShow() {
      return await AsyncStorage.getItem("dontshow");
    }

    getDontShow()
    .then((res) => {
      if (res !== "true") {
        toggle();
      }
    });
  }, []);

  return (
    <Fragment>
      {
        !hideHeader &&
        <AppBar title={t("choose-expert:title")} isBack />
      }
      <View style={{
        height: 70
      }}>
        <ScrollView
          horizontal={true}
          snapToInterval={Dimensions.get("window").width}
          showsHorizontalScrollIndicator={false}
          style={{
            height: "100%",
            flex: 1,
            paddingHorizontal: 16,
            paddingVertical: 12,
            backgroundColor: "#EEEAFF",
          }}
        >
          {menu.map((m) => {
            return (
              <TouchableOpacity
                key={m.id}
                style={{
                  flexDirection: "row",
                  backgroundColor: m.id === active ? "#6724E2" : "#FFF",
                  padding: 12,
                  gap: 6,
                  alignItems: "center",
                  borderRadius: 10,
                  height: 46,
                  maxHeight: 46,
                  marginRight: 12,
                }}
                onPress={() => {
                  setActive(m.id);
                  setType(m.id);
                }}
              >
                {m.icon(m.id === active ? "#FFF" : "#6724E2")}
                <View>
                  <Text
                    style={{
                      color: m.id === active ? "#FFF" : "#6724E2",
                      fontSize: 10,
                    }}
                  >
                    {m.text}
                  </Text>
                  <Text style={{
                      color: m.id === active ? "#FFF" : "#6724E2",
                      fontSize: 10,
                    }}>
                    {m.sub}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>

      <ScrollView style={styles.container}>
        {
          data.map((item, index) => {
            if (item?.speakerId === currentSpeakerId) {
              return <></>;
            }
            return <ListExpert t={t} key={index} data={item} type={type} status={status} toggle={toggle} />
          })
        }
        <View style={{ marginBottom: 16 }} />
      </ScrollView>
      <PopupFactory
        type="tips"
        visible={visible}
        onCancel={toggle}
        onSubmit={() => {
          toggle();
        }}
      />
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: "#EEEAFF",
  },
});
export default ChooseExpert;
