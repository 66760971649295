import React from "react";
import DatePicker from 'react-native-date-picker';
import moment from "moment";
import { Platform } from "react-native";

function DatePickerComponent(props) {
  if (Platform.OS !== "android" && Platform.OS !== "ios") {
    return <></>;
  }
  return (
    <DatePicker 
      modal  
      open={props?.open}
        date={props?.date}
        mode="date"
        onConfirm={(date) => {
          props?.setOpen(false);
          props?.setDate(date);
        }}
        onCancel={() => {
          props?.setOpen(false)
        }}
      />
  )
};
export default DatePickerComponent;
