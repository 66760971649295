import { ScrollView, View, Platform, Text } from "react-native";
import { StyleSheet } from "react-native";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
  AppBarChat,
  ChatList,
  EmptyState,
  InputChat,
  ModalFeedback,
  ModalOptionChat,
  Snackbar,
} from "../components";

import { timeCalculation } from "../services/services";
import { getChatHistory, getUserJID, initializeSdk, sendChat } from "../services/chat";
import { ChatIcon } from "../assets/icons";
import { useTranslation } from "react-i18next";
// import * as SDK from "mirrorfly-sdk";

function DetailChat2({route}) {
  const {params} = route;

  const [showOptionChat, setShowOptionChat] = useState(null);
  const [showSnackBar, setShowSnackbar] = useState(null);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [isSenderTyping, setIsSenderTyping] = useState(false);
  const [autoReply, setAutoReply] = useState(false);
  const [con, setCon] = useState(undefined);
  const [target, setTarget] = useState("");
  const [sender, setSender] = useState("");
  const [targetJid, setTargetJid] = useState("");
  const [chats, setChats] = useState([]);
  const [error, setError] = useState(false);
  const [calculatedTime, setCalculatedTime] = useState(-100);
  const [connect, setConnect] = useState(false);
  const [snackbarMsg, setSnackBarMsg] = useState("Sistem chat sedang bermasalah. Silahkan coba lagi.");
  const [isReady, setIsReady] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const [isLoadingChatHistory, setIsLoadingChatHistory] = useState(true);
  const scrollViewRef = useRef();
  const {t} = useTranslation("chat");
  
  useEffect(() => {
    // check the cons creator
    // if memberId == logged in memberId, then get current memberId as sender, 
    // and get the speakerMemberId as target
    // if memberId != logged, then get speakerMemberId as source
    // and use memberId as target
    if (params?.memberId === params?.loggedInMemberId) {
      setSender(params?.memberId);
      setTarget(params?.speakerMemberId)
    }

    if (params?.memberId !== params?.loggedInMemberId) {
      setSender(params?.speakerMemberId);
      setTarget(params?.memberId)
    }

  }, [params?.target, params?.memberId]);

  const messageListener = (res) => {
    if (res.msgType === "composing") {
      setIsSenderTyping(true);
      setTimeout(() => {
        setIsSenderTyping(false);
      }, 2000);
    }

    if (res.msgType === "receiveMessage") {
      setChats((prevState) => [
          ...prevState, 
          {
            message: res?.msgBody?.message,
            id: res?.msgId,
            timestamps: new Date(),
            repliedId: null,
            senderId: res.fromUserId,
            receiver: res.fromUserId === sender ? sender : target
          }
      ]);
    }
  }

  useEffect(() => {
    if (sender === "") {
      return;
    }

    initializeSdk(messageListener, sender)
    .then((res) => {
      setCon(res);
      setConnect(true);
    })
    .catch((e) => {
      console.log("SDK ERROR", e);
    });
  }, [sender]);

  useEffect(() => {
    const time = timeCalculation(params.timeUnformatted);
    // time range until service ended
    setCalculatedTime(time.diff);
  }, []);

  useEffect(() => {
    if (!con) {
      return;
    }

    getChatHistory(con, target, sender, target)
    .then((res) => {
      setChats(res);
      setIsLoadingChatHistory(false);
    });

    const jid = getUserJID(con, target);
    setTargetJid(jid);
  }, [con]);

  const defaultChat = {
    message: "",
    id: chats?.length + 1,
    timestamps: new Date(),
    repliedId: null,
    senderId: sender,
    receiver: 2,
  };
  const [currentChat, setCurrentChat] = useState(defaultChat);

  const onSendChat = async (chat) => {
    if (!connect) {
      setSnackBarMsg(t("connecting"));
      return setError(true);
    }
    if (!isReady) {
      setSnackBarMsg(t("not-opened"));
      return setError(true);
    }

    if (chat.replace(/ /g, "") === "") {
      setSnackBarMsg(t("empty"));
      return setError(true);
    }
    // if (isDone) {
    //   setSnackBarMsg("Konsultasi sudah ditutup");
    //   return setError(true);
    // }
    const jid = con.getJid(target);
    let res = {};
    if (Platform.OS === "ios" || Platform.OS === "android") {
      res = await con.sendTextMessage(jid.userJid, chat, params?.consId+"-"+(new Date().getTime()));
    } else {
      // res = await con.sendTextMessage({
      //   toJid: jid.userJid, 
      //   messageText: chat,
      //   replyMessageId: "",
      //   msgId: params?.consId+"-"+(new Date().getTime()),
      // });
      res = await con.sendTextMessage(
        jid.userJid, 
        chat,
        params?.consId+"-"+(new Date().getTime()),
      );
    }
    if (res.statusCode === 200) {
      sendChat(target, chat, sender, params?.consId);
      setChats((prevState) => [
        ...prevState,
        {
          ...currentChat,
          message: chat,
          senderId: sender,
          receiver: target,
          id: params?.consId+"-"+(new Date().getTime())
        },
      ]);
      setCurrentChat(defaultChat);
    } else {
      setSnackBarMsg(t("problem"));
      setError(true);
    }
  };

  const onReply = (repliedMessage) => {
    setCurrentChat((prevState) => ({ ...prevState, repliedId: repliedMessage }));
  };


  useEffect(() => {
    if (autoReply) {
      setIsSenderTyping(true);
      setAutoReply(false);
    }
  }, [autoReply]);

  return (
    <Fragment>
      <AppBarChat 
        t={t}
        calculatedTime={calculatedTime} 
        isSenderTyping={isSenderTyping} 
        data={params} 
        target={target} 
        setOpenFeedback={setOpenFeedback} 
        consId={params?.consId}
        setIsReady={setIsReady}
        setIsDone={setIsDone}
        isDone={isDone}
        isReady={isReady}
      />
      {
          isLoadingChatHistory &&
          <View style={{
            height: "100%",
            flex: 1,
            backgroundColor: "#EEEAFF"
          }}>
            <EmptyState
              Icon={ChatIcon}
              title={t("loading")}
              desc=""
            />
          </View>
        }
        {
          !isLoadingChatHistory &&
          <ScrollView style={styles.container} 
            ref={scrollViewRef}
            onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}
          >
              <ChatList
                senderId={sender}
                chats={chats}
                setShowOptionChat={setShowOptionChat}
                consId={params?.consId}
              />
            <View style={{ marginBottom: 16 }} />
          </ScrollView>
        }
        {
          !isLoadingChatHistory &&
          <InputChat
            onSendChat={onSendChat}
            chats={chats}
            connect={connect}
            SDK={con}
            targetJid={targetJid}
            currentChat={currentChat}
            time={calculatedTime}
            data={params}
            onCancelReply={() => {
              setCurrentChat({ ...currentChat, repliedMessage: null });
            }}
            isDone={isDone}
            isReady={isReady}
          />
        }
      <ModalFeedback
        isDetail
        visible={openFeedback}
        hideDialog={() => {
          setOpenFeedback(false);
        }}
        setSuccess={setShowSnackbar}
      />
      <ModalOptionChat
        onReply={onReply}
        repliedMessage={showOptionChat}
        setShowSnackbar={setShowSnackbar}
        visible={showOptionChat !== null}
        hideDialog={() => setShowOptionChat(null)}
        consId={route?.params.consId}
      />
      <Snackbar
        visible={showSnackBar !== null}
        hideDialog={() => {
          setShowSnackbar(null);
        }}
        title={
          showSnackBar === "journal"
            ? t("saved")
            : showSnackBar === "feedback" && t("feedback")
        }
      />
      <Snackbar
        visible={error}
        hideDialog={() => {
          setError(false)
        }}
        title={snackbarMsg}
      />
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: "#EEEAFF",
  },
});
export default DetailChat2;
