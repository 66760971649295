export const paymentInstructions = [{
  "id": "mandiri",
  "title": "Bank Mandiri",
  "methods": [
      {
          "id": "mandiri-online",
          "title": "Mandiri Online",
          "instructions": [
              "Login ke Internet Banking Mandiri (https://ibank.bankmandiri.co.id/)",
              "Pada menu utama, pilih Bayar, lalu pilih Multi Payment",
              "Pilih akun Anda di Dari Rekening, kemudian di Penyedia Jasa pilih Midtrans",
              "Masukkan Kode Pembayar an Anda dan klik Lanjutkan",
              "Konfirmasi pembayaran Anda menggunakan Mandiri Token atau aplikasi Mandiri Online"
          ]
      },
      {
          "id": "atm",
          "title": "ATM",
          "instructions": [
              "Pada menu utama, pilih Bayar/Beli",
              "Pilih Lainnya",
              "Pilih Multi Payment",
              "Masukkan 70012 (kode perusahaan Midtrans) lalu tekan Benar",
              "Masukkan Kode Pembayaran Anda lalu tekan Benar",
              "Pada halaman konfirmasi akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya"
          ]
      }
  ]
},
{
  "id": "bni",
  "title": "BNI",
  "methods": [
      {
          "id": "internetBanking",
          "title": "Internet Banking",
          "instructions": [
              "Ketik alamat https://ibank.bni.co.id kemudian klik Masuk",
              "Silakan masukkan User ID dan Password",
              "Klik menu Transfer kemudian pilih Tambah Rekening Favorit",
              "Masukkan nama, nomor rekening, dan email, lalu klik Lanjut",
              "Masukkan Kode Otentikasi dari token Anda dan klik Lanjut",
              "Kembali ke menu utama dan pilih Transfer lalu Transfer Antar Rekening BNI",
              "Pilih rekening yang telah Anda favoritkan sebelumnya di Rekening Tujuan lalu lanjutkan pengisian, dan tekan Lanjut",
              "Pastikan detail transaksi Anda benar, lalu masukkan Kode Otentikasi dan tekan Lanjut"
          ]
      },
      {
          "id": "mobileBanking",
          "title": "Mobile Banking",
          "instructions": [
              "Buka aplikasi BNI Mobile Banking dan login",
              "Pilih menu Transfer",
              "Pilih menu Virtual Account Billing",
              "Pilih rekening debit yang akan digunakan",
              "Pilih menu Input Baru dan masukkan 16 digit nomor Virtual Account",
              "Informasi tagihan akan muncul pada halaman validasi",
              "Jika informasi telah sesuai, masukkan Password Transaksi dan klik Lanjut",
              "Transaksi Anda akan diproses"
          ]
      },
      {
          "id": "atm",
          "title": "ATM",
          "instructions": [
              "Pada menu utama, pilih Menu Lainnya",
              "Pilih Transfer",
              "Pilih Rekening Tabungan",
              "Pilih Ke Rekening BNI",
              "Masukkan nomor virtual account dan pilih Tekan Jika Benar",
              "Masukkan jumlah tagihan yang akan anda bayar secara lengkap. Pembayaran dengan jumlah tidak sesuai akan otomatis ditolak",
              "Jumlah yang dibayarkan, nomor rekening dan nama Merchant akan ditampilkan. Jika informasi telah sesuai, tekan Ya",
              "Transaksi Anda sudah selesai"
          ]
      }
  ]
},
{
  "id": "bri",
  "title": "BRI",
  "methods": [
      {
          "id": "ibbri",
          "title": "IB BRI",
          "instructions": [
              "Masuk pada Internet Banking BRI",
              "Pilih menu Pembayaran & Pembelian",
              "Pilih sub menu BRIVA",
              "Masukkan Nomor BRIVA",
              "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Kirim",
              "Masukkan password dan mToken, pilih Kirim",
              "Pembayaran telah selesai. Untuk mencetak bukti transaksi, pilih Cetak"
          ]
      },
      {
          "id": "brimo",
          "title": "BRImo",
          "instructions": [
              "Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking BRI",
              "Pilih Pembayaran, lalu pilih BRIVA",
              "Masukkan nomor BRIVA",
              "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Continue",
              "Masukkan Mobile Banking BRI PIN, pilih Ok",
              "Pembayaran telah selesai. Simpan notifikasi sebagai bukti pembayaran"
          ]
      },
      {
          "id": "atm",
          "title": "ATM",
          "instructions": [
              "Pilih menu utama, pilih Transaksi Lain",
              "Pilih Pembayaran",
              "Pilih Lainnya",
              "Pilih BRIVA",
              "Masukkan Nomor BRIVA pelanggan dan pilih Benar",
              "Jumlah pembayaran, nomor BRIVA dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Ya",
              "Pembayaran telah selesai. Simpan bukti pembayaran Anda"
          ]
      }
  ]
},
{
  "id": "permata",
  "title": "Permata",
  "methods": [
      {
          "id": "atm",
          "title": "ATM",
          "instructions": [
              "Pada menu utama, pilih Transaksi Lainnya",
              "Pilih Pembayaran",
              "Pilih Pembayaran Lainnya",
              "Pilih Virtual Account",
              "Masukkan 16 digit No. Virtual Account yang dituju, lalu tekan Benar",
              "Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening, & nama Merchant. Jika informasi telah sesuai tekan Benar",
              "Pilih rekening pembayaran Anda dan tekan Benar"
          ]
      }
  ]
},
{
  "id": "bca",
  "title": "BCA",
  "methods": [
      {
          "id": "klikbca",
          "title": "Klik BCA",
          "instructions": [
              "Pilih menu Transfer Dana",
              "Pilih Transfer ke BCA Virtual Account",
              "Masukkan nomor BCA Virtual Account, atau pilih Dari Daftar Transfer",
              "Jumlah yang akan ditransfer, nomor rekening dan nama merchant akan muncul di halaman konfirmasi pembayaran, jika informasi benar klik Lanjutkan",
              "Ambil BCA Token Anda dan masukkan KEYBCA Response APPLI 1 dan Klik Submit",
              "Transaksi Anda selesai"
          ]
      },
      {
          "id": "m-bca",
          "title": "m-BCA",
          "instructions": [
              "Lakukan log in pada aplikasi BCA Mobile",
              "Pilih menu m-BCA, kemudian masukkan kode akses m-BCA",
              "Pilih m-Transfer > BCA Virtual Account",
              "Pilih dari Daftar Transfer, atau masukkan Nomor Virtual Account tujuan",
              "Masukkan jumlah yang ingin dibayarkan",
              "Masukkan pin m-BCA",
              "Pembayaran selesai. Simpan notifikasi yang muncul sebagai bukti pembayaran"
          ]
      },
      {
          "id": "atm",
          "title": "ATM",
          "instructions": [
              "Pada menu utama, pilih Transaksi Lainnya.",
              "Pilih Transfer",
              "Pilih Ke Rek BCA Virtual Account",
              "Masukkan Nomor Rekening pembayaran (11 digit) Anda lalu tekan Benar",
              "Masukkan jumlah tagihan yang akan anda bayar",
              "Pada halaman konfirmasi transfer akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya"
          ]
      }
  ]
},
{
  "id": "bankLain",
  "title": "Bank Lain",
  "methods": [
      {
          "id": "prima",
          "title": "Prima",
          "instructions": [
              "Pada menu utama, pilih Transaksi Lainnya",
              "Pilih Transfer",
              "Pilih Ke Rek Bank Lain",
              "Masukkan 002 (kode Bank BRI) lalu pilih Benar",
              "Masukkan Jumlah Nominal/Tagihan yang akan dibayar, pilih Benar",
              "Masukkan nomor BRIVA, pilih Benar",
              "Jumlah pembayaran, nomor virtual account, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Benar"
          ]
      },
      {
          "id": "atmBersama",
          "title": "ATM Bersama",
          "instructions": [
              "Pada menu utama, pilih Lainnya",
              "Tekan menu Transfer",
              "Pilih menu Antar Bank Online",
              "Masukkan 002 (kode Bank BRI) dan nomor BRIVA, lalu pilih Benar",
              "Masukkan Jumlah Nominal/Tagihan yang akan dibayar, pilih Benar",
              "Kosongkan nomor referensi transfer, pilih Benar",
              "Jumlah pembayaran, nomor virtual account, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Benar"
          ]
      },
      {
          "id": "alto",
          "title": "Alto",
          "instructions": [
              "Pada menu utama, pilih Transaksi Lainnya",
              "Pilih Transfer",
              "Pilih Ke Rek. Bank Lain",
              "Masukkan Jumlah Nominal/Tagihan yang akan dibayar, pilih Benar",
              "Masukkan 002 (kode Bank BRI) lalu pilih Benar",
              "Masukkan nomor BRIVA, lalu pilih Benar",
              "Jumlah pembayaran, nomor virtual account, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Benar"
          ]
      }
  ]
}];