import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Font from "expo-font";
import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import Chat from "./src/Pages/Chat";
import ChooseExpert from "./src/Pages/ChooseExpert";
import ConsultationForm from "./src/Pages/ConsultationForm";
import DetailExpert from "./src/Pages/DetailExpert";
import DetailOrder from "./src/Pages/DetailOrder";
import Home from "./src/Pages/Home";
import Journal from "./src/Pages/Journal";
import Login from "./src/Pages/Login";
import PlaceOrder from "./src/Pages/PlaceOrder";
import ProductDetail from "./src/Pages/ProductDetail";
import Profile from "./src/Pages/Profile";
import ProfileForm from "./src/Pages/ProfileForm";
import Program from "./src/Pages/Program";
import ProgramDetail from "./src/Pages/ProgramDetail";
import SpeakerFeedback from "./src/Pages/SpeakerFeedback";
import Verification from "./src/Pages/Verification";
// import DetailChat from "./src/Pages/DetailChat";
import WriteJournal from "./src/Pages/WriteJournal";
import Chat2 from "./src/Pages/Chat2";
import DetailChat2 from "./src/Pages/DetailChat2";
import Order from "./src/Pages/Order";
import Terms from "./src/Pages/Terms";
import Condition from "./src/Pages/Condition";
import LandingPage from "./src/Pages/LandingPage";
import Consultation from "./src/Pages/Consultation";
import Kota from "./src/Pages/Kota";
import Agenda from "./src/Pages/Agenda";
import Packages from "./src/Pages/Packages";
import { getPermission } from "./src/services/firebase";
import SplashScreen from "react-native-splash-screen";
import "./src/translation";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Stack = createNativeStackNavigator();

Font.loadAsync({
  "Archivo-Regular": require("./src/assets/fonts/Archivo-Regular.ttf"),
  "Archivo-Bold": require("./src/assets/fonts/Archivo-Bold.ttf"),
  "Archivo-SemiBold": require("./src/assets/fonts/Archivo-SemiBold.ttf"),
  "Archivo-Medium": require("./src/assets/fonts/Archivo-Medium.ttf"),
  "Archivo-SemiBoldItalic": require("./src/assets/fonts/Archivo-SemiBoldItalic.ttf"),
});

export default function App() {
  useEffect(() => {
    if (Platform.OS !== "web") {
      SplashScreen.hide();
      getPermission();
    }

    async function getLang() {
      return await AsyncStorage.getItem("lang");
    }

    getLang()
    .then((res) => {
      if (!res) {
        AsyncStorage.setItem("lang", "id");
      }
    });
  }, []);
  return ( <NavigationContainer
          documentTitle={{
              formatter: (options, route) =>
                  `Fammi: Edu-Health CARE`,
          }}

      >
        <Stack.Navigator initialRouteName={"LandingPage"}>
        <Stack.Screen
            name="ProgramDetail"
            component={ProgramDetail}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Program"
            component={Program}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="ProductDetail"
            component={ProductDetail}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Journal"
            component={Journal}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="SpeakerFeedback"
            component={SpeakerFeedback}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="ChooseExpert"
            component={ChooseExpert}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="DetailExpert"
            component={DetailExpert}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="PlaceOrder"
            component={PlaceOrder}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="ConsultationForm"
            component={ConsultationForm}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="DetailOrder"
            component={DetailOrder}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Profile"
            component={Profile}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Chat"
            component={Chat2}
            options={{
              headerShown: false,
            }}
          />

          <Stack.Screen
            name="Login"
            component={Login}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Verification"
            component={Verification}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Home"
            component={Home}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="ProfileForm"
            component={ProfileForm}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="DetailChat"
            component={DetailChat2}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="WriteJournal"
            component={WriteJournal}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Order"
            component={Order}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Terms"
            component={Terms}
            options={{
              headerShown: false,
            }}
          />

          <Stack.Screen
            name="Condition"
            component={Condition}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="LandingPage"
            component={LandingPage}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Consultation"
            component={Consultation}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Kota"
            component={Kota}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Agenda"
            component={Agenda}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Packages"
            component={Packages}
            options={{
              headerShown: false,
            }}
          />

        </Stack.Navigator>
      </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
