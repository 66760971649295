import React from "react";
import { StyleSheet, Text, View } from "react-native";

const EmptyState = ({ Icon, title, desc }) => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.icon}>
          <Icon width={24} height={24} fill={"#6724E2"} stroke={"none"} />
        </View>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.desc}>{desc}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    alignItems: "center",
  },
  icon: {
    width: 60,
    height: 60,
    padding: 18,
    borderRadius: 40,
    backgroundColor: "#E2DCFC",
  },
  title: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 15,
    marginTop: 24,
    marginBottom: 12,
    textAlign: "center",
  },
  desc: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 18,
    textAlign: "center",
  },
});

export default EmptyState;
