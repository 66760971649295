import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { AddIcon, StarFillIcon } from "../assets/icons";
import {
  AppBar,
  EmptyState,
  FAB,
  ListProgram,
  ModalAddProgram,
  Snackbar,
} from "../components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getProgram } from "../services/programs";

const DUMMY_CODE = "DESA-RZ";

const Program = (props) => {
  const [programs, setPrograms] = useState([]);
  const [addProgramModal, setAddProgramModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loadProgram, setLoadProgram] = useState(true);
  const [snackBarMessage, setSnackbarMessage] = useState("");
  const [token, setToken] = useState(undefined);
  const [code, setCode] = useState("");

  const onAddProgram = () => {
    // if (code !== DUMMY_CODE) {
      setSnackbarMessage("Kode program tidak valid. Silakan cek kembali");
    // } else {
      setSnackbarMessage("Program berhasil ditambahkan");
    //   setPrograms([
    //     {
    //       id: 1,
    //       title: "Desa Ramah Lansia",
    //       img: "https://i.ibb.co/3BdJcV9/image-565.png",
    //     },
    //   ]);
    // }
    setOpenSnackBar(true);
  };

  useEffect(() => {
    if (!loadProgram) {
      return;
    }

    async function getMember() {
      return await AsyncStorage.getItem("token");
    }

    getMember()
    .then((res) => {
      setToken(res);
      getProgram(13, res)
      .then((res) => {
        setPrograms(res?.data);
        setLoadProgram(false);
      })
    })
  }, [loadProgram]);

  return (
    <React.Fragment>
      <AppBar title="Program" isBack />
      {
        programs?.length === 0 &&
        <ScrollView contentContainerStyle={styles.container}>
            <EmptyState
              Icon={StarFillIcon}
              title="Kamu Belum Punya Program"
              desc="Klik tombol tambahkan program di bawah ini untuk menginput kode program"
            />
        </ScrollView>
      }
      {
        programs?.length > 0 &&
        <ScrollView style={styles.container}>
          {
            programs.map((p) => <ListProgram key={p.id} data={p} />)
          }
        </ScrollView>
      }
      <FAB
        icon={<AddIcon />}
        label="Tambah Program"
        onPress={() => setAddProgramModal(true)}
      />
      <ModalAddProgram
        token={token}
        code={code}
        setCode={setCode}
        onAddProgram={onAddProgram}
        setLoadProgram={setLoadProgram}
        visible={addProgramModal}
        hideDialog={() => setAddProgramModal(false)}
      />
      <Snackbar
        title={snackBarMessage}
        visible={openSnackBar}
        hideDialog={() => {
          setOpenSnackBar(false);
        }}
      />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#EEEAFF",
  },
  fab: {
    height: 34,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#6724E2",
    borderRadius: 100,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
    position: "absolute",
    bottom: 12,
    right: 12,
  },
  fabLabel: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#fff",
  },
});

export default Program;
