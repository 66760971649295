import Svg, { Path } from "react-native-svg";
const ArrowLiteRight = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#fff"
      d="M11.4 7.869 9.922 6.39 7.515 3.984c-.51-.503-1.38-.143-1.38.577v8.88c0 .72.87 1.08 1.38.57l3.885-3.885a1.598 1.598 0 0 0 0-2.257Z"
    />
  </Svg>
);
export default ArrowLiteRight;
