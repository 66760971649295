import React, { Fragment, useEffect, useState } from "react";
import { Linking, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import {
  BigCheckIcon,
  Calendar,
  ChevronDown,
  ChevronUp,
  CopyIcon,
  MoneyIcon,
  PaperIcon,
  RefreshIcon,
} from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import Clipboard from "@react-native-clipboard/clipboard";
import moment from "moment";
import { emoney } from "../services/transactions";
import { paymentInstructions } from "../services/payment_instructions";

function BoxOrder(props) {
  const { setOpenSnackBar, setMessageSnackBar, remainingTime, isPaid, data, setRefresh, t } = props;
  const [note, setNote] = useState({});
  const [duration, setDuration] = useState("");
  const [serviceDuration, setServiceDuration] = useState(undefined);
  const [timeDiff, setTimeDiff] = useState(100);
  const [paymentInstructionId, setPaymentInstructionId] = useState("");
  const navigation = useNavigation();
  const appPay = ["gopay", "shopeepay"];
  const onCopyText = (text, message) => {
    if (text) {
      Clipboard.setString(text);
    }
    setMessageSnackBar(message);
    setOpenSnackBar(true);
  };

  useEffect(() => {
    if (!data || !data?.note) {
      return;
    }

    setNote(JSON.parse(data?.note));
  }, [data]);

  useEffect(() => {
    if (!note || !note?.date) {
      return;
    }

    const startTime = note?.time?.split("-")[0];
    const endTime = note?.time?.split("-")[1];
    const consultationDate = moment(note?.date).format("YYYY-MM-DD");
    const consultationStartTime = moment(consultationDate+" "+startTime);
    const consultationEndTime = moment(consultationDate+" "+endTime);
    const timeInterval = consultationEndTime.diff(consultationStartTime, "minute");
    setServiceDuration(timeInterval);
  }, [note]);

  useEffect(() => {
    const theTimeoutId = setTimeout(() => {
      if (timeDiff <= 0) {
        return;
      }

      const displayedDuration = [];
      const limitTime = moment(data?.created);
      const currentTime = moment();
      
      const theDiff = limitTime.diff(currentTime, "seconds");

      const hours = Math.floor(theDiff / 3600);
      const minutes = Math.floor((theDiff % 3600) / 60);
      const seconds = Math.floor((theDiff % 3600) % 60);

      if (hours > 0) {
        displayedDuration.push(hours);
      }

      if (minutes > 0) {
        displayedDuration.push(('0'+minutes).slice(-2));
      }

      if (seconds > 0) {
        displayedDuration.push(('0'+seconds).slice(-2));
      }

      setDuration(displayedDuration.join(":"))
      
      const newDiff = limitTime.diff(currentTime, "seconds");
      setTimeDiff(newDiff);
    }, 1000);

    if (timeDiff <= 0) {
      clearTimeout(theTimeoutId);
    }

  }, [timeDiff, data]);
  return (
    <Fragment>
      <View style={styles.parentBox}>
        <BigCheckIcon />
        <Text style={styles.txBig}>
          {
            isPaid &&
            t("detail-order:paid")
          }
          {
            !isPaid &&
            t("detail-order:order-made")
          }
        </Text>
        <View style={isPaid ? styles.labelSuccess : styles.labelPending}>
          <Text style={styles.txLabel}> {isPaid ? t("detail-order:paid") : `${t("detail-order:remaining-time")} ${( duration )}`}</Text>
        </View>
      </View>
      <View style={styles.boxTwo}>
        <View>
          <View style={styles.flexed}>
            <MoneyIcon />
            <Text style={styles.txTitle}>{t("detail-order:payment-details")}</Text>
          </View>
          {
            data?.paymentMethod !== "fammi-kuota" &&
            appPay.indexOf(data?.paymentMethod) < 0 &&
            <View
              style={{
                ...styles.flexed,
                justifyContent: "space-between",
                marginTop: 12,
                marginBottom: 12,
              }}
            >
              <Text style={styles.txSpan}>{t("detail-order:va-number")}</Text>
              <View>
                <TouchableOpacity
                  onPress={() => {
                    onCopyText(
                      data?.vaNumber?.replace(" 70012", ""),
                      t("detail-order:success-copy-va")
                    );
                  }}
                  style={styles.flexed}
                >
                  <CopyIcon />
                  <Text style={styles.txMain}>{data?.vaNumber?.replace(" 70012", "")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          }
          <View
            style={{
              ...styles.flexed,
              justifyContent: "space-between",
              marginBottom: 12,
            }}
          >
            <Text style={styles.txSpan}>{t("detail-order:total")}</Text>
            <View>
              <TouchableOpacity
                onPress={() => {
                  onCopyText(data?.total, t("detail-order:success-copy-total"));
                }}
                style={styles.flexed}
              >
                <CopyIcon />
                <Text style={styles.txMain}>Rp{parseFloat(data?.total).toLocaleString("id-ID")}</Text>
              </TouchableOpacity>
            </View>
          </View>
          {
            data?.discount !== "0" &&
            <View
              style={{
                ...styles.flexed,
                justifyContent: "space-between",
                marginBottom: 12,
              }}
            >
              <Text style={styles.txSpan}>{t("detail-order:initial-amount")}</Text>
              <View style={styles.flexed}>
                <Text style={{...styles.txMain, color: "#999"}}>Rp{parseFloat(data?.amount).toLocaleString("id-ID")}</Text>
              </View>
            </View>
          }
          {
            data?.discount !== "0" &&
            <View
              style={{
                ...styles.flexed,
                justifyContent: "space-between",
                marginBottom: 12,
              }}
            >
              <Text style={styles.txSpan}>{t("detail-order:discount")}</Text>
              <View style={styles.flexed}>
                <Text style={{...styles.txMain, color: "#999"}}>Rp{parseFloat(data?.discount).toLocaleString("id-ID")}</Text>
              </View>
            </View>
          }
          <View
            style={{
              ...styles.flexed,
              justifyContent: "space-between",
              marginBottom: 12,
            }}
          >
            <Text style={styles.txSpan}>{t("detail-order:payment-method")}</Text>
            {
              data?.paymentMethod === "fammi-kuota" &&
              <Text style={{...styles.txTitle, textTransform: "capitalize"}}>Kuota</Text>
            }

            {
              data?.paymentMethod !== "fammi-kuota" &&
              appPay.indexOf(data?.paymentMethod) < 0 &&
              <Text style={{...styles.txTitle, textTransform: "capitalize"}}>Bank {data?.paymentMethod?.replace("echannel", "mandiri")}</Text>
            }
            
            {
              data?.paymentMethod !== "fammi-kuota" &&
              appPay.indexOf(data?.paymentMethod) > -1 &&
              <Text style={{...styles.txTitle, textTransform: "capitalize"}}>{emoney[data?.paymentMethod]["name"]}</Text>
            }
            
          </View>
          <View
            style={{
              ...styles.flexed,
              justifyContent: "space-between",
              marginBottom: 12,
            }}
          >
            <Text style={styles.txSpan}>{t("detail-order:pay-before")}</Text>
            <Text style={styles.txTitle}>
              {
                moment(data?.created).format("DD MMM YYYY HH:mm")
              }
            </Text>
          </View>
          <View
            style={{
              ...styles.flexed,
              justifyContent: "space-between",
              marginBottom: 24,
            }}
          >
            <Text style={styles.txSpan}>No Invoice</Text>
            <Text style={styles.txTitle}>{data?.code}</Text>
          </View>
        </View>
        {
          data?.paymentMethod !== "fammi-kuota" &&
          <View>
            <View style={styles.flexed}>
              <PaperIcon />
              <Text style={styles.txTitle}>{t("detail-order:how-pay")}</Text>
            </View>
            <View>
              {
                paymentInstructions.map((p) => {
                  if (p.id !== data?.paymentMethod?.replace("echannel", "mandiri")) {
                    return;
                  }
                  return p.methods.map((m, index) => {
                    return <View>
                    <TouchableOpacity
                      style={{
                        ...styles.flexed,
                        justifyContent: "space-between",
                        marginTop: index > 0 ? 0 : 12,
                        marginBottom: 12,
                      }}
                      onPress={() => {
                        if (paymentInstructionId === m.id) {
                          return setPaymentInstructionId("");
                        }

                        setPaymentInstructionId(m.id);
                      }}
                    >
                      <Text style={styles.txSpan}>
                        {m.title}
                      </Text>
                      {
                        paymentInstructionId === m.id ? <ChevronUp /> : <ChevronDown />
                      }
                    </TouchableOpacity>
                    {
                      paymentInstructionId === m.id &&
                      <View style={{marginBottom: 12}}>
                      {
                        m.instructions.map((i, index) => {
                          return <View style={{
                            flexDirection: "row",
                            gap: 2,
                            fontFamily: "Archivo",
                          }}>
                            <Text style={{fontSize: 12}}>
                              {index * 1 + 1}.
                            </Text>
                            <Text style={{fontSize: 12}}>
                              {i}
                            </Text>
                          </View>
                        })
                      }
                      </View>
                    }
                    </View>
                  })
                })
              }
            </View>
            {/* <TouchableOpacity
              style={{
                ...styles.flexed,
                justifyContent: "space-between",
                marginBottom: 24,
              }}
            >
              <Text style={styles.txSpan}>ATM</Text>
              <ChevronDown />
            </TouchableOpacity> */}
          </View>
        }
        {
          note?.speakerName &&
          <View>
            <View style={styles.flexed}>
              <Calendar />
              <Text style={styles.txTitle}>{t("detail-order:consul-schedule")}</Text>
            </View>
            <View
              style={{
                ...styles.flexed,
                justifyContent: "space-between",
                marginTop: 12,
                marginBottom: 12,
              }}
            >
              <Text style={styles.txSpan}>{t("detail-order:expert")}</Text>
              <Text ellipsizeMode="tail" numberOfLines={1} style={{...styles.txTitle, maxWidth: "50%"}}>
                {note?.speakerName}
              </Text>
            </View>
            <View
              style={{
                ...styles.flexed,
                justifyContent: "space-between",
                marginBottom: 12,
              }}
            >
              <Text style={styles.txSpan}>{t("detail-order:date-time")}</Text>
              <Text style={styles.txTitle}>{moment(note?.date).format("DD MMM YYYY")} {note?.time} WIB</Text>
            </View>
            <View
              style={{
                ...styles.flexed,
                justifyContent: "space-between",
                marginBottom: 12,
              }}
            >
              <Text style={styles.txSpan}>{t("detail-order:duration")}</Text>
              <Text style={styles.txTitle}>{serviceDuration} {t("detail-order:minute")}</Text>
            </View>
            <View
              style={{
                ...styles.flexed,
                justifyContent: "space-between",
                marginBottom: !isPaid ? 24 : 0,
              }}
            >
              <Text style={styles.txSpan}>Media</Text>
              <Text style={styles.txTitle}>Chatting</Text>
            </View>
          </View>
        }
        {!isPaid && (
          <View>
            <View style={styles.flexed}>
              <RefreshIcon />
              <Text style={styles.txTitle}>{t("detail-order:payment-status")}</Text>
            </View>
            <View
              style={{
                ...styles.flexed,
                justifyContent: "space-between",
                marginTop: 12,
                marginBottom: 12,
              }}
            >
              <Text style={styles.txPending}>{t("detail-order:not-yet-paid")}</Text>
              <TouchableOpacity
                onPress={() => {
                  setRefresh(new Date().getTime());
                  // onCopyText(null, "Pembayaran belum diterima");
                }}
              >
                <Text style={styles.textAction}>{t("detail-order:check")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
      {
        !isPaid &&
        appPay.indexOf(data?.paymentMethod) > -1 &&
        <TouchableOpacity
          onPress={() => {
            Linking.openURL((data?.vaNumber))
          }}
          style={styles.btnConsul}
        >
          <Text style={styles.txConsul}>Bayar di aplikasi {emoney[data?.paymentMethod]['appName']}</Text>
        </TouchableOpacity>
      }
      {isPaid && (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Home", {
              newTime: new Date().getTime()
            });
          }}
          style={styles.btnConsul}
        >
          <Text style={styles.txConsul}>{t("detail-order:back-to-home")}</Text>
        </TouchableOpacity>
      )}
    </Fragment>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 16,
    flexDirection: "column",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#C9C1EC",
    borderStyle: "dashed",
  },

  boxTwo: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 16,
  },
  flexed: {
    flexDirection: "row",
    alignItems: "center",
  },
  txBig: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 15,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 16,
    marginBottom: 6,
  },
  labelPending: {
    backgroundColor: "#FF0000",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
    paddingTop: 6,
    paddingRight: 12,
    paddingBottom: 6,
    paddingLeft: 12,
  },
  labelSuccess: {
    backgroundColor: "#4EB826",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
    paddingTop: 6,
    paddingRight: 12,
    paddingBottom: 6,
    paddingLeft: 12,
  },
  txLabel: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    // lineHeight: 11,
    color: "#FFFFFF",
    // padding: 12
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    marginLeft: 6,
  },
  txPending: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#FF0000",
    marginLeft: 6,
  },
  txSpan: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
    color: "#999999",
  },
  txMain: {
    fontFamily: "Archivo-Semibold",
    fontStyle: "normal",
    marginLeft: 6,
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#6724E2",
  },
  textAction: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#6724E2",
  },
  btnConsul: {
    marginTop: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    height: 36,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  txConsul: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
  },
});

export default BoxOrder;
