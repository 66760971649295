import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, ScrollView, BackHandler } from "react-native";
import { AppBar, BoxOrder, Snackbar } from "../components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getTransaction } from "../services/transactions";
import { useNavigation } from "@react-navigation/native";

function DetailOrder({route}) {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [remainingTime, setRemainingTime] = useState(60);
  const [isPaid, setIsPaid] = useState(false);
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(new Date().getTime());


  const backToHome = () => {
    navigation.navigate("Home");
    return true;
  };

  BackHandler.addEventListener('hardwareBackPress', backToHome);

  useEffect(() => {
    async function getTransactionId() {
      const transactionId = route?.params?.transactionId ? route?.params?.transactionId : await AsyncStorage.getItem("0b806ea")
      return transactionId;
    }

    getTransactionId()
    .then((res) => {
      getTransaction(res)
      .then((res) => {
        setData(res?.data[0]);
        const data = res?.data[0];
        if (data?.status === "3") {
          setIsPaid(true);
        }
      })
    });
  }, [refresh]);

  return (
    <Fragment>
      <AppBar title={t("detail-order:order")} isBack jump={"Home"}/>
      <ScrollView style={styles.container}>
        <BoxOrder
          t={t}
          data={data}
          remainingTime={remainingTime}
          isPaid={isPaid}
          setOpenSnackBar={setOpenSnackBar}
          setMessageSnackBar={setMessageSnackBar}
          setRefresh={setRefresh}
        />
        <View style={{ marginBottom: 16 }} />
      </ScrollView>
      <Snackbar
        visible={openSnackBar}
        hideDialog={() => {
          setOpenSnackBar(false);
        }}
        title={messageSnackBar}
      />
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: "#EEEAFF",
  },
  btnConsul: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    paddingTop: 10,
    paddingRight: 7,
    paddingBottom: 10,
    paddingLeft: 7,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  txConsul: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
    marginLeft: 6,
  },
  flexed: {
    flexDirection: "row",
    alignItems: "center",
  },
});
export default DetailOrder;
