import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { AddIcon, CalendarIcon, StarFillIcon } from "../assets/icons";
import {
  AppBar,
  EmptyState,
} from "../components";
import { useTranslation } from "react-i18next";


const Agenda = (props) => {
  const {t} = useTranslation("agenda");
  return (
    <React.Fragment>
      <AppBar title="Agenda" isBack />
      <ScrollView contentContainerStyle={styles.container}>
          <EmptyState
            Icon={CalendarIcon}
            title={t("agenda:soon")}
            desc={t("agenda:soon-desc")}
          />
      </ScrollView>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#EEEAFF",
  },
  fab: {
    height: 34,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#6724E2",
    borderRadius: 100,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
    position: "absolute",
    bottom: 12,
    right: 12,
  },
  fabLabel: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#fff",
  },
});

export default Agenda;
