import {
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { CloseCircleIcon, Message2, SendIcon } from "../assets/icons";
import { Fragment, useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getSpeaker } from "../services/speakers";
import { getConsultants } from "../services/consultants";
import { useTranslation } from "react-i18next";

function InputChat(props) {
  const { onSendChat, chats, currentChat, onCancelReply, time, SDK, targetJid, connect, data, isDone, isReady } = props;
  const [state, setState] = useState({
    chat: "",
    height: 0,
  });
  const replyMessage = currentChat?.repliedMessage
    ? currentChat?.repliedMessage
    : null;
  const navigation = useNavigation();
  const [isWriteJournal, setIsWriteJournal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation("chat")

  useEffect(() => {
    setIsWriteJournal(data?.memberId !== data?.loggedInMemberId);
  }, [data?.memberId, data?.loggedInMemberId]);
  return (
    <Fragment>
      {replyMessage && (
        <View style={styles.parentReply}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.textReply}>Balas: </Text>
            <Text style={styles.textReply}>{replyMessage}</Text>
          </View>
          <Pressable onPress={onCancelReply}>
            <CloseCircleIcon />
          </Pressable>
        </View>
      )}
      <View style={styles.parent}>
        {
        ((time > 0 &&
        !isDone))
         && (
          <Fragment>
            <TextInput
              multiline={true}
              onChangeText={(text) => {
                setState({
                  ...state,
                  chat: text,
                });
              }}
              onChange={(e) => {
                setState({
                  ...state,
                  chat: e.target.value,
                });
              }}
              onContentSizeChange={(event) => {
                setState({
                  ...state,
                  height: event.nativeEvent.contentSize.height,
                });
              }}
              style={[styles.inputText, { height: Math.max(35, state.height) }]}
              placeholder={!connect ? t("loading") : t("typing")}
              value={state.chat}
              onKeyPress={() => {
                SDK.sendTypingStatus(targetJid);
              }}
            />
            <Pressable
              onPress={() => {
                onSendChat(state.chat.trim());
                setState({ chat: "", height: 0 });
              }}
            >
              <SendIcon />
            </Pressable>
          </Fragment>
        )}

        {
          isDone &&
          isWriteJournal &&
          (
          <TouchableOpacity
            onPress={async () => {
              if (data?.memberId === data?.loggedInMemberId) {
                return navigation.navigate("Journal");
              }
    
              const speakerId = await AsyncStorage.getItem("792f3e1");
              getSpeaker(speakerId)
              .then((r) => {
                const speakerType = r?.data[0].speakerType;
                navigation.navigate("WriteJournal", {
                  speakerType: speakerType,
                  speaker: r?.data[0],
                  consId: data?.consId
                });
                return;
              })
            }}
            style={styles.btnConsul}
          >
            <Text style={{ ...styles.txConsul, marginLeft: 0 }}>
              {t("write-journal")}
            </Text>
          </TouchableOpacity>
          )
        }
        {
          isDone &&
          !isWriteJournal &&
          <TouchableOpacity 
            style={styles.btnConsul}
            onPress={() => {
              setIsLoading(true);
              getSpeaker(data?.speakerId)
              .then((res) => {
                const speakerData = res.data[0];

                getConsultants(speakerData.speakerType)
                .then((res) => {
                  const consultants = res?.data?.filter((r) => r.speakerId === speakerData.speaker_id);
                  setIsLoading(false);

                  if (consultants.length > 0) {
                    return navigation.navigate("DetailExpert", {
                      type: speakerData.speakerType,
                      status: "general",
                      speaker: consultants[0],
                    });
                  }

                  return navigation.navigate("ChooseExpert", {
                    type: "all"
                  });
                });
              });
            }}
          >
            <Message2 />
            <Text style={styles.txConsul}>
              {
                isLoading ? t("loading"): t("re-consult")
              }
            </Text>
          </TouchableOpacity>
        }
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  parent: {
    backgroundColor: "#FFFFFF",
    padding: 10,
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  parentReply: {
    backgroundColor: "#E2DCFC",
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },

  textReply: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    color: "#000",
  },
  inputText: {
    borderWidth: 1,
    borderColor: "#EAEAEA",
    borderStyle: "solid",
    borderTopLeftRadius: 18,
    borderTopRightRadius: 18,
    borderBottomRightRadius: 18,
    borderBottomLeftRadius: 18,
    fontFamily: "Archivo-Regular",
    paddingTop: 12,
    paddingRight: 16,
    paddingBottom: 12,
    paddingLeft: 16,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    width: "90%",
  },
  btnConsul: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    height: 36,
    width: "100%",
    paddingTop: 7,
    paddingRight: 7,
    paddingBottom: 7,
    paddingLeft: 7,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  txConsul: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
    marginLeft: 6,
  },
});
export default InputChat;
