import Svg, { Path } from "react-native-svg";
const PenBold = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#000"
      d="M12 1.5H6C3 1.5 1.5 3 1.5 6v9.75c0 .413.337.75.75.75H12c3 0 4.5-1.5 4.5-4.5V6c0-3-1.5-4.5-4.5-4.5ZM8.332 12.495a1.61 1.61 0 0 1-.787.398l-1.56.217c-.06.008-.12.015-.173.015a.94.94 0 0 1-.675-.262c-.21-.21-.3-.518-.247-.848l.218-1.56c.037-.255.21-.607.397-.787L8.332 6.84a4.543 4.543 0 0 0 .57 1.103c.075.112.15.21.203.262.03.045.06.075.067.09.165.188.338.367.503.502.045.045.075.068.082.075.098.075.188.158.278.21.098.075.203.143.307.203.128.075.263.15.405.217.143.068.278.12.413.165l-2.828 2.828Zm4.08-4.072-.585.585a.205.205 0 0 1-.142.06c-.015 0-.045 0-.06-.008A3.929 3.929 0 0 1 8.94 6.375a.192.192 0 0 1 .053-.195l.592-.592c.967-.968 1.882-.946 2.827 0 .48.48.72.944.713 1.425 0 .472-.232.93-.713 1.41Z"
    />
  </Svg>
);
export default PenBold;
