import { Fragment, useEffect, useState } from "react";
import {
  AppBar,
  BottomNavigation,
  ListChat,
  ModalFeedback,
  Snackbar,
} from "../components";
import { ScrollView, StyleSheet } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

function Chat() {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [successFeedback, setSuccesssFeedback] = useState(false);
  const [mode, setMode] = useState("client");
  const [chat, setChat] = useState([]);

  useEffect(() => {
    async function getMode() {
      return await AsyncStorage.getItem("mode");
    }

    getMode()
    .then((res) => {
      setMode(res);

      // filter message
      let chatFiltered = [];
      if (res.indexOf("client") > -1) {
        chatFiltered = clientChat.filter((c) => {
          return c.mode.toString() === res
        });
      } else {
        chatFiltered = clientChat.filter((c) => {
          return c.target.toString() === res
        });
      }

      setChat(chatFiltered);

      // if (res !== "client") {
        // const chatFiltered = clientChat.filter((c) => {
        //   return c.target.toString() === res
        // });

        // setChat(chatFiltered);
      // } else {
      //   setChat(clientChat);
      // }
    })
  }, []);

  const clientChat = [
    {
      title: "Mengatasi Bullying",
      name: "Intan Mardhanti Pracylyaningrum",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-1",
      target: "expert-1",
    },
    {
      title: "Depresi Sebagai Orangtua",
      name: "Anggi Rengganis",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-2",
      target: "expert-2"
    },
    {
      title: "Cara Agar Ikhlas",
      name: "Issara Rizkya",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-3",
      target: "expert-3"
    },
    {
      title: "Cara Agar Ikhlas",
      name: "Issara Rizkya",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-4",
      target: "expert-3"
    },
    {
      title: "Cara Agar Ikhlas",
      name: "Issara Rizkya",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-5",
      target: "expert-3"
    },
    {
      title: "Cara Agar Sehat",
      name: "Fauziah Syarifatul Huriyah ",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client",
      target: "expert-4"
    },
    {
      title: "Belajar Lapang Dada",
      name: "Nadhira Rakhmi Yusuf",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client",
      target: "expert-5"
    },
    {
      title: "Belajar Tersenyum",
      name: "Army Triasari",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-1",
      target: "expert-6"
    },
    {
      title: "Melawan Batas",
      name: "Sofia Nurinawati",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-2",
      target: "expert-7"
    },
    
    {
      title: "Tokoh Mengatasi Bullying",
      name: "Nila Andriani",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-1",
      target: "figure-1",
    },
    {
      title: "Tokoh Depresi Sebagai Orangtua",
      name: "Purwanto",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-2",
      target: "figure-2"
    },
    {
      title: "Tokoh Cara Agar Ikhlas",
      name: "Sulistiyani, S.Pd",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-3",
      target: "figure-3"
    },
    {
      title: "Tokoh Cara Agar Sehat",
      name: "Ila Royanti,S.pd ",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-4",
      target: "figure-4"
    },
    {
      title: "Tokoh Belajar Lapang Dada",
      name: "Hasya Rahmania Maulani",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-5",
      target: "figure-5"
    },
    {
      title: "Tokoh Belajar Mengatur Target",
      name: "Nana Fachrudin",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-1",
      target: "figure-6"
    },
    {
      title: "Tokoh Belajar Mengatur Waktu",
      name: "Hendra Aditiyawijaya",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-2",
      target: "figure-7"
    },

    {
      title: "Sahabat Mengatasi Bullying",
      name: "Qatrinnadya P. Elvinaro",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-1",
      target: "partner-1",
    },
    {
      title: "Sahabat Depresi Sebagai Orangtua",
      name: "Dhini Aulia Ardhan",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-2",
      target: "partner-2"
    },
    {
      title: "Sahabat Cara Agar Ikhlas",
      name: "Arie Cipta Laras Metti",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-3",
      target: "partner-3"
    },
    {
      title: "Sahabat Cara Agar Sehat",
      name: "Dyah Fazriya Astuti",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-4",
      target: "partner-4"
    },
    {
      title: "Sahabat Belajar Lapang Dada",
      name: "Shiffa Intan Amarthani",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-5",
      target: "partner-5"
    },
    {
      title: "Sahabat Belajar Mengatur Target",
      name: "Lies Diana Dwi Irawati",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-1",
      target: "partner-6"
    },
    {
      title: "Sahabat Belajar Mengatur Waktu",
      name: "Aprililla Susanti",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-2",
      target: "partner-7"
    },
    {
      title: "Sahabat Belajar Bersama",
      name: "Riva Ananda Putri ",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-3",
      target: "partner-8"
    },
    {
      title: "Sahabat Belajar Sendiri",
      name: "Intan S N",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-4",
      target: "partner-9"
    },
    {
      title: "Sahabat Belajar Berlari",
      name: "Syifa Shafira",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-5",
      target: "partner-10"
    },
    {
      title: "Sahabat Belajar Tidur",
      name: "Asma Halida SE",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-1",
      target: "partner-11"
    },
    {
      title: "Sahabat Berlari",
      name: "Nadya",
      time: "11:00",
      isRead: true,
      isLike: false,
      mode: "client-3",
      target: "partner-12"
    }
  ];

  return (
    <Fragment>
      <AppBar title="Chat" />
      <ScrollView style={styles.container}>
        {chat?.map((item, index) => (
          <ListChat
            data={item}
            mode={mode}
            key={index}
            isLast={index + 1 === chat?.length}
            setOpenFeedback={setOpenFeedback}
          />
        ))}
      </ScrollView>
      <Snackbar
        visible={successFeedback}
        hideDialog={() => {
          setSuccesssFeedback(false);
        }}
        title="Penilaian berhasil dikirim"
      />
      <ModalFeedback
        visible={openFeedback}
        hideDialog={() => {
          setOpenFeedback(false);
        }}
        setSuccess={setSuccesssFeedback}
      />
      <BottomNavigation activeIndex={1} />
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },
});
export default Chat;
