import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { DocumentIcon, DocumentTextIcon, MessengerFillIcon, UserGroup } from "../assets/icons";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";

const AgendaCard = (props) => {
  const {data, userMode, token, t} = props;
  const [isReady, setIsReady] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [defaultImage, setDefaultImage] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    if (!data?.consultationDate) {
      return;
    }

    const consultationStartedTime = data?.consultationDate+" "+data?.consultationHour.split("-")[0];
    const consultationEndedTime = data?.consultationDate+" "+data?.consultationHour.split("-")[1];
    let ready = false;
    if (moment() >= moment(consultationStartedTime) && moment() <= moment(consultationEndedTime)) {
      ready = true;
    }

    setIsReady(ready);
    let image = data?.memberPhoto;
    if (data?.memberId === token) {
      image = data?.speakerImage;
    }
    setImageSrc(image);
  }, [data?.consultationDate]);

  let duration = "";
  const scheduledHour = data?.consultationHour?.split("-")[0];
  const scheduledData = moment(data?.consultationDate+" "+scheduledHour+":00");

  const durationDay = scheduledData.diff(moment(), "days");
  if (Math.abs(durationDay) > 0) {
    duration = `${durationDay} hari lagi`;
  }

  const durationHour = scheduledData.diff(moment(), "hours");
  if (Math.abs(durationHour) < 24) {
    duration = `${durationHour} jam lagi`;
  }

  const durationMinute = scheduledData.diff(moment(), "minutes");
  if (Math.abs(durationMinute) < 60) {
    duration = `${durationMinute} menit lagi`;
  }

  const durationSeconds = scheduledData.diff(moment(), "seconds");
  if (Math.abs(durationSeconds) < 60) {
    duration = `${durationSeconds} detik lagi`;
  }

  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <View style={styles.calendar}>
          <View style={styles.month}>
            <Text style={styles.monthText}>
              {
                moment(data?.consultationDate).format("MMM")
              }
            </Text>
          </View>
          <View style={styles.date}>
            <Text style={styles.title}>
            {
                moment(data?.consultationDate).format("DD")
              }
            </Text>
          </View>
        </View>

        <View>
          <Text style={styles.title}>{t("agenda-card:subtitle")}</Text>
          <Text style={styles.time}>
            {
              data?.consultationHour
            } WIB
            <Text style={{ color: "#4EB826", fontFamily: "Archive-Bold" }}>
              &nbsp;~&nbsp;{duration < 0 ? t("agenda-card:started") : duration}
            </Text>
          </Text>
        </View>
      </View>
      
      <View style={{...styles.bottom, padding: 12, gap: 9}}>
        <View style={{width: 40, alignItems: "center"}}>
          {
            (imageSrc === "" || !imageSrc || defaultImage) &&
            <UserGroup/>
          }
          {
            imageSrc && imageSrc !== "" && !defaultImage &&
            <Image
              source={{
                uri: token === data?.memberId && imageSrc !== "" ? 
                `https://fammi.ly/uploads/speakers/images/${imageSrc}` :
                (token !== data?.memberId && imageSrc !== "" ? 
                `https://fammi.ly/uploads/members/images/${imageSrc}` :
                `https://via.placeholder.com/40?mode=${data?.mode}`) 
              }}
              style={{...styles.profilePic}}
              onError={()=>setDefaultImage(true)}
            /> 
          }
        </View>
          <View style={{width: "80%"}}>
            <Text
              ellipsizeMode="tail"
              numberOfLines={1}
              style={{...styles.profileName, width: "100%"}}
            >
              {
                data?.memberId === token ?
                data?.speakerName :
                data?.memberName
              }
            </Text>
              {
                data?.memberId === token &&
                <Text style={{fontSize: 12, color: "#999"}}>
                {
                  data?.speakerDesc
                }
                </Text>
              }
          </View>
      </View>

      <View style={styles.bottom}>
        <TouchableOpacity 
          style={styles.bottomBox}
          onPress={() => {
            navigation.navigate("Consultation", {
              consId: data?.consId
            })
          }}
        >
          <DocumentIcon fill="#000"/>
          <Text style={{fontFamily: "Archivo-SemiBold", fontSize: 12, fontWeight: 500}}>
          {t("agenda-card:notes")}
          </Text>
        </TouchableOpacity>
        <View style={styles.divider} />
          <View style={{ ...styles.bottomBox, columnGap: 6 }}>
            <TouchableOpacity
              onPress={() => {
                if (!isReady) {
                  return;
                }
                const consultationEnd = moment(data.consultationDate+' '+data.consultationHour.split("-")[1]);
                navigation.navigate("DetailChat", {
                  ...data,
                  timeUnformatted: consultationEnd,
                  name: data.memberId === userMode.memberId ? data?.speakerName : data?.memberName,
                  target: data.memberId === userMode.memberId ? data.speakerId : data.memberId,
                  photo: data.memberId === userMode.memberId ? data.speakerImage : data.memberPhoto,
                  memberId: data.memberId,
                  loggedInMemberId: token,
                  speakerMemberId: data?.speakerMemberId
                });
              }}
              style={{
                flexDirection: "row",
                alignItems:"center", 
                columnGap: 6
              }}
            >
              <MessengerFillIcon width={22} height={22} fill={`${isReady ? "#6724E2" : "#999"}`} />
              <Text style={{ ...styles.profileName, color: isReady ? "#6724E2" : "#999" }}>
              {t("agenda-card:chat")}
              </Text>
            </TouchableOpacity>
          </View>
      </View>
      <View style={styles.borderBottom} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: "#fff",
  },
  top: {
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 12,
    borderBottomColor: "#EAEAEA",
    borderBottomWidth: 1,
    borderStyle: "dashed",
  },
  calendar: {
    width: 40,
    height: 40,
  },
  month: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 4,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  date: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 4,
    backgroundColor: "#EEEAFF",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  monthText: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 11,
    color: "#fff",
  },
  title: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
  },
  time: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    color: "#999",
    marginTop: 4,
  },
  bottom: {
    flexDirection: "row",
    alignItems: "center",
  },
  bottomBox: {
    width: "50%",
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    columnGap: 10,
  },
  profilePic: {
    width: 40,
    height: 40,
    borderRadius: 40 / 2,
    borderWidth: 1,
    borderColor: "#CCC"
  },
  profileName: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
  },
  divider: {
    alignSelf: "center",
    backgroundColor: "#eaeaea",
    height: 22,
    width: 1,
  },
  borderBottom: {
    width: "100%",
    height: 6,
    backgroundColor: "#6724E2",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
});

export default AgendaCard;
