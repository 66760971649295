import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  Image,
} from "react-native";
import {
  AppBar,
  InfoOrder,
  InformationExpert,
  ModalPaymentMethod,
  ModalVoucher,
  Snackbar,
} from "../components";
import { ArrowLiteRight, WalletIcon } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import { getServicePrice } from "../services/services";
import { addToCart, buyWithCredit, getVaCode, getVoucher, setVa } from "../services/transactions";
import md5 from "md5";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getMemberCredits } from "../services/member_credits";
import moment from "moment";

function PlaceOrder({route}) {
  const [openVoucher, setOpenVoucher] = useState(false);
  const [method, setMethod] = useState(null);
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState("Pilih metode pembayaran sebelum lanjut");
  const [token, setToken] = useState("");

  const [openMethod, setOpenMethod] = useState(false);
  const [isError, setIsError] = useState(false);
  const [price, setPrice] = useState({});
  const [speakerData, setSpeakerData] = useState({});
  const [transactionId, setTransactionId] = useState(undefined);
  const [credits, setCredits] = useState([]);
  const [voucherCode, setVoucherCode] = useState("");
  const navigation = useNavigation();
  const {speaker, chosenDateSchedule, chosenHourSchedule, product, type, status} = route.params;

  useEffect(() => {
    if (!speaker) {
      return;
    }

    async function getToken() {
      return await AsyncStorage.getItem("token");
    }

    setSpeakerData(speaker);

    getServicePrice(speaker?.speakerId)
    .then((res) => {
      const priceObj = {};
      res?.data?.map((d) => {
        if (d?.setId === "b1454d43dabf1f502eabf7f79f1ce96e") {
          priceObj['nominal'] = d?.price;
        } else {
          priceObj['credit'] = d?.price;
        }
      });

      setPrice(priceObj);
      setTransactionId(md5(new Date()));
    });

    getToken()
    .then((res) => {
      setToken(res);
      getMemberCredits(res)
      .then((res) => {
        setCredits(res?.data);
      });
    });
  }, [speaker]);

  useEffect(() => {
    setTransactionId(md5(new Date()));
  }, [product]);

  return (
    <Fragment>
      <AppBar title={t("detail-order:order")} isBack />
      <ScrollView style={styles.container}>
        {
          speaker &&
          <InformationExpert data={speakerData} t={t} />
        }
        {
          product &&
          <View style={{
            marginBottom: 12
          }}>
            <Image
              source={{ uri: `https://fammi.ly/uploads/products/images/${product?.productImage}` }}
              style={{
                width: "100%",
                height: 100,
                borderRadius: 10,
              }}
            />
          </View>
        }
        <InfoOrder
          setOpenVoucher={setOpenVoucher}
          setOpenMethod={setOpenMethod}
          method={method}
          chosenSchedule={{chosenDateSchedule, chosenHourSchedule}}
          product={product}
          status={status}
          voucherCode={voucherCode}
          t={t}
        />
        {
          status?.indexOf("program") > -1 &&
          <TouchableOpacity
            onPress={() => {
              const amount = price['nominal'];

              const productId = speakerData?.speakerId;
              let note = {
                  speakerId: speaker?.speakerId,
                  image: speakerData?.photo,
                  speakerName: speaker?.name,
                  speakerDesc: speaker?.description,
                  date: chosenDateSchedule,
                  time: chosenHourSchedule
                };
              addToCart(transactionId, {
                discountPrice: amount,
                productId: productId,
                paymentMethod: "fammi-kuota",
                note: note
              }).then(async (res) => {
                await AsyncStorage.setItem("0b806ea", transactionId);

                buyWithCredit(token, type, status, {
                  bookTime: moment(chosenDateSchedule).format("YYYY-MM-DD")+' '+chosenHourSchedule.split("-")[0],
                  speakerId: speaker?.speakerId,
                  memberId: token,
                  bookEndedTime: moment(chosenDateSchedule).format("YYYY-MM-DD")+' '+chosenHourSchedule.split("-")[1],
                  transactionId
                }).then((res) => {
                  if (res.data === true) {
                    navigation.navigate("ConsultationForm", {
                      transactionId: transactionId,
                      speakerId: speaker?.speakerId,
                      consultationDate: chosenDateSchedule,
                      consultationHour: chosenHourSchedule,
                      consultationType: "chat"
                    });

                    return;
                  } 

                  setErrorMsg("Pembayaran dengan kuota gagal. Silahkan gunakan metode lain");
                  return setIsError(true);
                });
              });
            }}
            style={styles.btnConsul}
          >
            <View style={styles.flexed}>
              <WalletIcon />
              <Text style={styles.txConsul}>{t("detail-order:free")}</Text>
            </View>
            <View style={styles.flexed}>
              <Text style={{ ...styles.txConsul, fontFamily: "Archivo-Regular" }}>
                Lanjut
              </Text>
              <ArrowLiteRight />
            </View>
          </TouchableOpacity>
        }
        {
          (status?.indexOf("program") < 0 || !status) &&
          <TouchableOpacity
            onPress={() => {
              if (!method) {
                setErrorMsg("Silahkan pilih metode pembayaran")
                setIsError(true);
                return;
              }

              if (method.id === "fammi-kuota" && product) {
                setErrorMsg("Kuota hanya bisa digunakan untuk konsultasi")
                setIsError(true);
                return;
              }

              const amount = product ? product.productPrice : price['nominal'];

              const productId = product ? product.productId : speakerData?.speakerId;
              let note = {};
              if (speaker) {
                note = {
                  speakerId: speaker?.speakerId,
                  image: speakerData?.photo,
                  speakerName: speaker?.name,
                  speakerDesc: speaker?.description,
                  date: chosenDateSchedule,
                  time: chosenHourSchedule
                };
              } else {
                note = {
                  additionalContentId: product?.additionalContentId,
                  additionalName: product?.productName,
                  additionalDescriptions: product?.additionalDescriptions,
                  additionalNotes: product?.additionalDescriptions,
                }
              }

              addToCart(transactionId, {
                discountPrice: amount,
                productId: productId,
                paymentMethod: method.id,
                note: note
              }).then(async (res) => {
                await AsyncStorage.setItem("0b806ea", transactionId);
                let discount = 0;
                if (voucherCode !== "") {
                  const voucher = await getVoucher({
                    transactionId: transactionId,
                    code: voucherCode
                  });
                  discount = voucher?.data;
                }

                if (method.id === "fammi-kuota") {
                  buyWithCredit(token, type, status, {
                    bookTime: moment(chosenDateSchedule).format("YYYY-MM-DD")+' '+chosenHourSchedule.split("-")[0],
                    speakerId: speaker?.speakerId,
                    memberId: token,
                    bookEndedTime: moment(chosenDateSchedule).format("YYYY-MM-DD")+' '+chosenHourSchedule.split("-")[1],
                    transactionId
                  }).then((res) => {
                    if (res.data === true) {
                      navigation.navigate("ConsultationForm", {
                        transactionId: transactionId,
                        speakerId: speaker?.speakerId,
                        consultationDate: chosenDateSchedule,
                        consultationHour: chosenHourSchedule,
                        consultationType: "chat"
                      });

                      return;
                    } 

                    setErrorMsg("Pembayaran dengan kuota gagal. Silahkan gunakan metode lain");
                    return setIsError(true);
                  });

                  return;
                }

                getVaCode({
                  order_id: transactionId, 
                  gross_amount: amount - discount,
                  payment_method: method.id
                }).then((res) => {
                  if (!res?.data?.vaNumber && !res?.data?.actions?.[0]) {
                    alert("Terjadi kesalahan");
                    return;
                  }

                  let vaNumber = "";
                  if (res?.data?.vaNumber) {
                    vaNumber = res?.data?.vaNumber;
                  }
                  if (res?.data?.actions?.[0].name === "deeplink-redirect") {
                    vaNumber = (res?.data?.actions?.[0].url);
                  }
                  // if (res?.data?.actions?.[1].name === "deeplink-redirect") {
                  //   vaNumber = (res?.data?.actions?.[1].url);
                  // }

                  setVa({
                    transactionId: transactionId, 
                    paymentMethod: method.id, 
                    vaNumber: vaNumber
                  }).then(async (res) => {
                    if (speaker) {
                      navigation.navigate("ConsultationForm", {
                        transactionId: transactionId,
                        speakerId: speaker?.speakerId,
                        consultationDate: chosenDateSchedule,
                        consultationHour: chosenHourSchedule,
                        consultationType: "chat"
                      });
                    } 

                    if (product) {
                      navigation.navigate("DetailOrder", {
                        transactionId: await AsyncStorage.getItem("0b806ea")
                      });
                    }
                  })
                });

              });
            }}
            style={styles.btnConsul}
          >
            <View style={styles.flexed}>
              <WalletIcon />
              <Text style={styles.txConsul}>{t("product-detail:price")} Rp
              {
                speaker &&
                parseFloat(price?.nominal)?.toLocaleString("id-ID")
              } 
              {
                product &&
                parseFloat(product?.productPrice)?.toLocaleString("id-ID")
              } 
              </Text>
              {
                speaker &&
                <Text style={{ ...styles.txConsul, fontFamily: "Archivo-Regular" }}>
                  / 30 mnt
                </Text>
              }
            </View>
            <View style={styles.flexed}>
              <Text style={{ ...styles.txConsul, fontFamily: "Archivo-Regular" }}>
                {t("detail-order:next")}
              </Text>
              <ArrowLiteRight />
            </View>
          </TouchableOpacity>
        }
        <View style={{ marginBottom: 16 }} />
      </ScrollView>
      <ModalVoucher
        t={t}
        visible={openVoucher}
        setVoucherCode={setVoucherCode}
        voucherCode={voucherCode}
        setErrorMsg={setErrorMsg}
        setIsError={setIsError}
        hideDialog={() => {
          setOpenVoucher(false);
        }}
      />
      <ModalPaymentMethod
        setMethod={setMethod}
        visible={openMethod}
        credits={credits}
        hideDialog={() => {
          setOpenMethod(false);
        }}
      />
      <Snackbar
        visible={isError}
        hideDialog={() => {
          setIsError(false);
        }}
        title={errorMsg}
      />
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: "#EEEAFF",
  },
  btnConsul: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    paddingTop: 10,
    paddingRight: 7,
    paddingBottom: 10,
    paddingLeft: 7,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  txConsul: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
    marginLeft: 6,
  },
  flexed: {
    flexDirection: "row",
    alignItems: "center",
  },
});
export default PlaceOrder;
