import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Modal from "react-native-modal";
import { BigHeartCircle, CloseIcon, HeartCircle } from "../assets/icons";
import { useState } from "react";
import { sendFeedback } from "../services/feedback";
import md5 from "md5";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";

function ModalFeedback(props) {
  const { visible, hideDialog, setSuccess, isDetail, data } = props;
  const [rate, setRate] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation("speaker_feedback");
  const rateValue = t("feedback-rate:rate-list", { returnObjects: true });

  const onSubmit = async () => {
    if (rate < 1 || feedback === "") {
      return setIsError(true);
    }

    sendFeedback( {
      feedbackId: md5(new Date()),
      memberId: await AsyncStorage.getItem("token"),
      rate: rate, 
      descriptions: feedback,
      speakerId: data.speakerId, 
      consId: data.consId
    }).then(() => {
      setSuccess(true);
      hideDialog();
    });
  };
  return (
    <Modal isVisible={visible} onBackdropPress={hideDialog}>
      <View style={styles.parentBox}>
        <View
          style={{
            ...styles.flexOne,
            justifyContent: "space-between",
            marginBottom: 12,
          }}
        >
          <View style={styles.flexOne}>
            <HeartCircle customColor="#000" />
            <Text style={styles.txTitle}>{t("popup.title")}</Text>
          </View>
          <TouchableOpacity onPress={hideDialog}>
            <CloseIcon />
          </TouchableOpacity>
        </View>
        <View style={styles.mainSection}>
          <View
            style={{
              ...styles.flexOne,
              justifyContent: "center",
              marginBottom: 6,
            }}
          >
            {[1, 2, 3, 4, 5].map((item, index) => (
              <TouchableOpacity
                onPress={() => {
                  setRate(item);
                  console.log(rateValue);
                }}
                key={index}
              >
                <BigHeartCircle customColor={rate >= item ? "red" : "#999"} />
              </TouchableOpacity>
            ))}
          </View>
          <Text
            style={{
              ...styles.txSpan,
              color: rate > 3 ? "#4EB826" : "#999999",
            }}
          >
            {rate === 0
              ? t("popup-feedback:desc")
              : rateValue[rate-1]}
          </Text>
          <TextInput
            style={[
              styles.inputStyle,
              isError && {
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#ff0000",
              },
            ]}
            placeholder={t("popup.field_placeholder")}
            multiline={true}
            numberOfLines={4}
            onChangeText={(text) => {
              setFeedback(text);
            }}
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
            value={feedback}
          />
        </View>
        <TouchableOpacity onPress={onSubmit} style={styles.btnConsul}>
          <Text style={styles.txConsul}>{t("popup.cta_button")}</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 12,
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center",
  },

  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    marginLeft: 6,
  },
  mainSection: {
    marginTop: 24,
    marginBottom: 12,
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  txSpan: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 10,
    lineHeight: 11,
    textAlign: "center",
    color: "#999999",
  },
  inputStyle: {
    textAlignVertical: 'top',
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#DEDEDE",
    borderStyle: "solid",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    paddingTop: 12,
    paddingRight: 12,
    paddingBottom: 12,
    paddingLeft: 12,
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    width: "100%",
    marginTop: 24,
  },
  btnConsul: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    paddingTop: 10,
    paddingRight: 7,
    paddingBottom: 10,
    paddingLeft: 7,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  txConsul: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
    marginLeft: 6,
  },
});
export default ModalFeedback;
