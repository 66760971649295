import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dimensions, Image, Linking, Pressable, RefreshControl, ScrollView, StatusBar, View } from "react-native";
import {
  AgendaCard,
  BottomNavigation,
  HomeHeader,
  HomeMenu,
  InvoiceCard,
  ListProgram,
  PopupFactory,
  PromoCard,
  SectionContent,
  ServiceCard,
  Snackbar,
} from "../components";
import { MoreMenuModal } from "../components/HomeMenu";
import { checkUpdate, getPackages } from "../services/packages";
import { getTransactionByMember } from "../services/transactions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getMemberAgenda } from "../services/agenda";
import { getMemberCredits } from "../services/member_credits";
import ModalKode from "../components/ModalKode";
import { useNavigation } from "@react-navigation/native";
import { getContents } from "../services/contents";
import { getProgram } from "../services/programs";
import { usePopup } from "../components/PopupFactory";
import { APP_VERSION } from "@env";
import "../translation";

function Home({route}) {
  const [isMoreMenuVisible, setIsMoreMenuVisible] = useState(false);
  const { toggle, visible } = usePopup();
  // const [visible, setVisible] = useState(false);
  const [packages, setPackages] = useState([]);
  const [freePackages, setFreePackages] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [credits, setCredits] = useState([]);
  const [isKodeVisible, setIsKodeVisible] = useState(false);
  const [code, setCode] = useState("");
  const [loadProgram, setLoadProgram] = useState(false);
  const [token, setToken] = useState(undefined);
  const [userMode, setUserMode] = useState("member");
  const [contents, setContents] = useState([]);
  const [lastPrograms, setLastPrograms] = useState(null);
  const [refreshing, setRefreshing] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const { t } = useTranslation();
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 2000);
  }, []);
  
  useEffect(() => {
    async function getToken() {
      return await AsyncStorage.getItem("token");
    }
    async function getMode() {
      const userMode = await AsyncStorage.getItem("792f3e7");
      setUserMode(userMode);
      return userMode;
    }

    getMode();

    checkUpdate()
    .then((res) => {
      if (APP_VERSION !== res?.data.v) {
        toggle();
      }
    });

    getPackages().then((res) => {
      const packs = res?.data;
      setPackages(packs);
      const freePacks = packs.filter((p) => p.productType === "12");
      setFreePackages(freePacks);
    });


    getToken().then((res) => {
      setToken(res);
      getTransactionByMember(res, "default").then((res) => {
        setTransactions(res?.data);
      });
    });

    getToken().then((res) => {
      getMemberAgenda("member", res).then((res) => {
        setAgendas(res?.data);
      });
    });

    getToken().then((res) => {
      getMemberCredits(res).then((res) => {
        setCredits(res?.data);
      });
    });

    getContents()
    .then((res) => {
      setContents(res?.data);
    })

    getToken().then((res) => {
      getProgram(13, res).then((response) => {
        setLastPrograms(response?.data?.length > 0 && response?.data?.[0]);
      });
    });
  }, [loadProgram, route?.params?.newTime, refreshing]);
  return (
    <>
      <StatusBar backgroundColor={"#6724E2"}/>
      <ScrollView style={{ flex: 1, background: "#EEEAFF", paddingBottom: 20 }} refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        <HomeHeader credits={credits} userMode={userMode}/>
        <HomeMenu onMore={() => setIsMoreMenuVisible(true)} t={t}/>
        {/* <KOTACard /> */}

        {lastPrograms?.productId && (
          <View style={{ marginBottom: 24 }}>
            <SectionContent title={null} hideAllButton>
              <ListProgram data={lastPrograms}  t={t}/>
            </SectionContent>
          </View>
        )}

        {transactions?.length > 0 &&
          (transactions?.[0]?.theNumber === "1" ||
            !transactions?.[0]?.timeLimit) && (
            <View style={{ marginBottom: 24 }}>
              <SectionContent title={t("invoice-card:title")} hideAllButton>
                <InvoiceCard data={transactions[0]} t={t} setShowSnackBar={setShowSnackBar} setSnackBarMsg={setSnackBarMsg}/>
              </SectionContent>
            </View>
          )}

        {agendas?.length > 0 && (
          <View style={{ marginBottom: 24 }}>
            <SectionContent title={t("agenda-card:title")} hideAllButton>
              <AgendaCard data={agendas[0]} userMode={userMode} token={token} t={t} />
            </SectionContent>
          </View>
        )}

        {freePackages.length > 0 && (
          <View style={{ marginBottom: 24 }}>
            <SectionContent title={t("promo-card:title")} hideAllButton>
              {freePackages.map(
                (f, index) =>
                  index < 1 && (
                    <PromoCard data={f} key={index} setIsKodeVisible={setIsKodeVisible} t={t} />
                  )
              )}
            </SectionContent>
          </View>
        )}

        {
          contents.length > 0 &&
          <View style={{ marginBottom: 24 }}>
          <SectionContent title={t("common:latest")} hideAllButton={true}>
            <ScrollView
              horizontal={true}
              snapToInterval={Dimensions.get("window").width}
              showsHorizontalScrollIndicator={false}
            >
              {
              contents.map((c, index) => (
                <Pressable key={index} onPress={() => {
                  Linking.openURL((c?.cta))
                }}>
                  <Image
                    source={{ uri: `https://fammi.ly/uploads/contents/images/${c?.image}` }}
                    style={{
                      width: Dimensions.get("window").width - 100,
                      maxWidth: 300,
                      height: 100,
                      marginRight: 12,
                      borderRadius: 10,
                    }}
                  />
                </Pressable>
              ))}
            </ScrollView>
          </SectionContent>
          </View>
        }

        <View style={{ marginBottom: 24 }}>
          <SectionContent title={t("common:packages")} hideAllButton={true}>
            <ScrollView
              horizontal={true}
              snapToInterval={Dimensions.get("window").width}
              showsHorizontalScrollIndicator={false}
            >
              {packages?.map((p, index) => {
                if (p.productType !== "8") {
                  return <></>;
                }
                return <ServiceCard key={index} data={p} t={t}/>;
              })}
            </ScrollView>
          </SectionContent>
        </View>

        <MoreMenuModal
          isVisible={isMoreMenuVisible}
          t={t}
          onClose={() => setIsMoreMenuVisible(false)}
        />
        <ModalKode
          visible={isKodeVisible}
          setIsKodeVisible={setIsKodeVisible}
          onClose={() => setIsKodeVisible(false)}
          setLoadProgram={setLoadProgram}
          code={code}
          token={token}
          setCode={setCode}
        />
      </ScrollView>
      <BottomNavigation activeIndex={0}/>
      <PopupFactory
        type={"updates"}
        visible={visible}
        onCancel={toggle}
        onSubmit={() => {
          toggle();
          Linking.openURL("market://details?id=ly.fammi.kota");
        }}
      />
      <Snackbar
        visible={showSnackBar}
        hideDialog={() => {
          setShowSnackBar(false);
        }}
        title={snackBarMsg}
      />
    </>
  );
}

export default Home;
