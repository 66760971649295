import axios from "axios";
import { Platform } from "react-native";
import {APP_CHAT_URL, API_URL} from "@env";
import * as SDKWeb from "mirrorfly-sdk";
import SDK from "./SDK";
import moment from "moment";

export const registerUser = (token) => {
    return axios.post(APP_CHAT_URL + `/register-user`, {
        "username": token, 
        "password": token, 
        "nickname": token
    });
}

export const getChatToken = (token) => {
    return axios.post(APP_CHAT_URL + `/get-user-token`, {
        "username": token
    });
}

export const getUserJID = (SDK, userId) => {
    const theTarget = SDK.getJid(userId);
    return theTarget.userJid;
}

export const getChatHistory = async (sdk, userId, sender, target) => {
    const userJid = getUserJID(sdk, userId);
    const result = await sdk.getChatMessages(userJid, "down");
    const chats = [];

    console.log("result". result);

    result?.data?.map((r) => {
      const originalTime = moment.utc(r.createdAt);
      chats.unshift({
        message: r.msgBody?.message,
        id: r.msgId,
        timestamps: originalTime.local().toDate(),
        senderId: r.fromUserId,
        receiver: r.fromUserId === sender ? sender : target,
      });
    });
    
    return Promise.resolve(chats);
}
const connectionListener = (res) => {
}
export const initializeSdk = async (messageListener, sender) => {
    const initializeObj = {
        apiBaseUrl: `https://fammi-api.mirrorfly.com/api/v1`,
        licenseKey: `bpgrL6jGs1MTFCH6jM4sBDjXJBVX1G`,
        callbackListeners: {
          messageListener,
          connectionListener
        },
      };

      if (Platform.OS === "android" || Platform.OS === "ios") {
        await SDK.initializeSDK(initializeObj);

        const reponses = await axios.post(`${APP_CHAT_URL}/register-user-m`, {
          identifier: sender
        });

        await SDK.connect(sender, reponses?.data?.token);
        return Promise.resolve(SDK);
      } else {
        await SDKWeb.initializeSDK(initializeObj);

        const reponses = await axios.post(`${APP_CHAT_URL}/register-user-m`, {
          identifier: sender
        });

        await SDKWeb.connect(sender, reponses?.data?.token);
        return Promise.resolve(SDKWeb);
      }
}

export const sendChat = (target, msg, sender, consId) => {
  return axios.post(`${API_URL}/fb`, {
    memberId: target,
    message: msg,
    sender, 
    consId
  });
}

export const getRecentChats = async (SDK) => {
    const chats = await SDK.getRecentChats();

    return Promise.resolve(chats);
}

export const getTarget = async (speakerId) => {
  return axios.get(`${API_URL}/loadmemberid/${speakerId}`);
}