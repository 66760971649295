import Svg, { Path } from "react-native-svg";
const SendIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none">
    <Path
      fill="#6724E2"
      d="M16.785 6.122 7.219 1.365C.794-1.835-1.843.788 1.375 7.177l.972 1.934c.28.567.28 1.222 0 1.79l-.972 1.922c-3.218 6.39-.592 9.012 5.844 5.812l9.566-4.757c4.29-2.133 4.29-5.623 0-7.756Zm-3.61 4.712H7.141A.842.842 0 0 1 6.303 10c0-.456.38-.833.838-.833h6.034c.458 0 .838.377.838.833 0 .456-.38.834-.838.834Z"
    />
  </Svg>
);
export default SendIcon;
