import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import { Calendar, Message2, MoneyIcon, PenBold } from "../assets/icons";
import { SvgUri } from "react-native-svg";

function InfoOrder(props) {
  const { setOpenVoucher, setOpenMethod, method, product, speaker, status, voucherCode, t } = props;
  const [serviceDuration, setServiceDuration] = useState(undefined);
  const [sectionList, setSectionList] = useState()
  const isAndroid = Platform.OS === "android";

  let timeInterval = "0";
  if (props.chosenSchedule?.chosenDateSchedule) {
    const theDate = props.chosenSchedule?.chosenDateSchedule;
    const theTime = props.chosenSchedule?.chosenHourSchedule;
    const startTime = theTime?.split("-")[0];
    const endTime = theTime?.split("-")[1];
    const consultationDate = moment(theDate).format("YYYY-MM-DD");
    const consultationStartTime = moment(consultationDate+" "+startTime);
    const consultationEndTime = moment(consultationDate+" "+endTime);
    timeInterval = consultationEndTime.diff(consultationStartTime, "minute");
  }
  
  let SECTION_LIST = [
    {
      title: t("detail-order:title"), // product ? "Detail Layanan" : "Detail Konsultasi",
      fields: product ? [
        {title: t("detail-order:package-name"), value: product?.productName},
        {title: t("detail-order:expiry"), value: "7 hari"}
      ] : [
        { title: t("detail-order:duration"), value: timeInterval+" "+t("detail-order:minute") },
        { title: "Media", value: "Chatting" },
      ],
      icons: <Message2 customColor="#000" circleColor="#fff" />,
    }
  ];

  if (status?.indexOf("program") < 0 || !status) {
    SECTION_LIST.push({
      title: t("detail-order:payment"),
      fields: [
        {
          title: t("detail-order:payment-method"),
          value: (
            <>
              {method ? (
                <TouchableOpacity
                  onPress={() => {
                    setOpenMethod(true);
                  }}
                  style={styles.flexOne}
                >
                  <View style={styles.parentImg}>
                    {isAndroid ? (
                      <SvgUri uri={method.img} height={16} width={40} />
                    ) : (
                      <img src={method.img} height={16} width={40} />
                    )}
                  </View>
                  <Text style={styles.txMain}>{method.title}</Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    setOpenMethod(true);
                  }}
                >
                  <Text style={styles.textAction}>{t("profile-form:job-placeholder")}</Text>
                </TouchableOpacity>
              )}
            </>
          ),
        },
        {
          title: "Kode Voucher",
          value: (
            <TouchableOpacity
              onPress={() => {
                setOpenVoucher(true);
              }}
            >
              <Text style={styles.textAction}>
                {
                  voucherCode !== "" ? t("detail-order:saved") : "Input"
                }
              </Text>
            </TouchableOpacity>
          ),
        },
      ],
      icons: <MoneyIcon />,
    });
  }
  if (speaker) {
    SECTION_LIST = [{
      title: "Jadwal Konsultasi",
      fields: [
        { title: "Tanggal & Hari", value: moment(props.chosenSchedule?.chosenDateSchedule).format("ddd, DD MMM YYYY") },
        { title: "Waktu", value: `${props.chosenSchedule?.chosenHourSchedule}` },
      ],
      icons: <Calendar />,
    }, ...SECTION_LIST];
  }

  return (
    <View style={styles.parentBox}>
      {SECTION_LIST.map((item, index) => (
        <View
          style={[
            styles.parentList,
            index + 1 !== SECTION_LIST.length && {
              borderBottomWidth: 1,
              borderStyle: "solid",
              borderBottomColor: "#eaeaea",
            },
          ]}
          key={index}
        >
          <View style={{ ...styles.flexOne, marginBottom: 12 }}>
            <View style={styles.flexOne}>
              {item.icons}
              <Text style={styles.txTitle}>{item.title}</Text>
            </View>
          </View>
          {item.fields.map((field, idx) => (
            <View
              key={idx}
              style={{
                ...styles.flexOne,
                justifyContent: "space-between",
                marginBottom: 12,
              }}
            >
              <Text style={styles.txSpan}>{field.title}</Text>
              <Text style={styles.txTitle}>{field.value}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginBottom: 16,
    paddingBottom: 10,
  },
  parentList: {
    padding: 16,
    paddingBottom: 4,
  },

  flexOne: {
    flexDirection: "row",
    alignItems: "center",
  },

  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    marginLeft: 6,
  },
  txSpan: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
    color: "#999999",
  },
  textAction: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#6724E2",
  },
  parentImg: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#DEDEDE",
    borderStyle: "solid",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    height: 36,
    width: 78,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  txMain: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    marginLeft: 12,
  },
});

export default InfoOrder;
