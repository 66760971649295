import React from "react";
import { ScrollView, Text, View } from "react-native";
import { AppBar } from "../components";
import { termsAndConditionStyles } from "./Terms";

const Condition = () => {
  return (
    <>
      <AppBar title="Kebijakan Data" isBack />
      <ScrollView contentContainerStyle={termsAndConditionStyles.container}>
        <View>
          <Text style={[termsAndConditionStyles.title]}>Kebijakan data</Text>
          <Text style={termsAndConditionStyles.text}>
          {"\n"}
Kebijakan data ini mencakup kebijakan data untuk layanan Fammi sebagai Layanan Belajar, Bertanya dan Diskusi seputar masalah Keluarga. Kebijakan data ini merupakan komitmen Kami sebagai Penyedia Layanan untuk menjaga kerahasiaan, keamanan dan integritas pengguna terhadap perlindungan data pribadi pengguna. Kebijakan data ini berlaku untuk merek, produk, atau layanan baik melalui Aplikasi maupun Website.
{"\n"}{"\n"}
Dengan mengunjungi Layanan Kami, Anda menerima dan menyetujui pendekatan dan cara-cara yang digambarkan dalam Kebijakan Privasi ini.
{"\n"}{"\n"}
Merujuk pada peraturan perlindungan regulasi data yang berlaku, termasuk Undang-Undang No. 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik, Peraturan Pemerintah No. 82 Tahun 2012 tentang Penyelenggaraan Sistem dan Transaksi Elektronik, serta Peraturan Menteri Komunikasi dan Informatika No. 20 Tahun 2016 tentang Perlindungan Data Pribadi dalam Sistem Elektronik (“Peraturan yang Berlaku”), penyelenggara sistem elektronik adalah PT Ruang Raya Indonesia dengan alamat Jalan Tanah Abang I Nomor 11F, Jakarta Pusat, DKI Jakarta, Indonesia.
{"\n"}{"\n"}{"\n"}
</Text>
<Text style={[termsAndConditionStyles.title]}>
Data Pengguna apa yang kami kumpulkan?
</Text>
<Text style={termsAndConditionStyles.text}>
{"\n"}
Informasi yang Anda berikan saat pendaftaran.{"\n"}
Saat mendaftar Layanan Fammi, kami akan mengumpulkan data pribadi seperti: Nama, email, dan Nomor Telepon. Hal ini disebut dengan Data Registrasi bersifat wajib. Anda dapat memberikan informasi melalui formulir elektronik pada Platform Kami.
{"\n"}{"\n"}
Informasi yang Kami kumpulkan.{"\n"}
Data perangkat. Kami mengumpulkan informasi dari perangkat yang pengguna gunakan untuk mengakses Layanan kami, Data perangkat ini mencakup data merek, manufaktur dan IP address dari perangkat yang digunakan. Kami mungkin akan mengaitkan informasi yang kami kumpulkan dengan perangkat pengguna yang akan membantu kami dalam memberi Layanan yang stabil ke perangkat yang digunakan.
{"\n"}{"\n"}
Data Profil. Kami mengumpulkan data pribadi seperti: Nama, usia, jenis kelamin dan profesi pada saat pertama kali Anda melengkapi profil di dalam aplikasi Fammi.
{"\n"}{"\n"}
Informasi yang Kami terima dari sumber lain{"\n"}
Kami dapat menerima informasi jika Anda menggunakan situs lain yang Kami operasikan atau Layanan lain yang Kami sediakan. Kami juga bekerja sama dengan pihak ketiga (termasuk, namun tidak terbatas pada misalnya, mitra bisnis, pembayaran, dll) dan dapat menerima informasi dari mereka. Kami akan mengambil langkah-langkah untuk melakukan verifikasi terhadap informasi yang Kami dapatkan sesuai dengan peraturan yang berlaku.
{"\n"}{"\n"}{"\n"}
</Text>

<Text style={[termsAndConditionStyles.title]}>
Bagaimana kami menggunakan data yang kami kumpulkan
</Text>

<Text style={termsAndConditionStyles.text}>
{"\n"}
Secara umum. Kami bertanggung jawab untuk memproses Data Pendaftaran Anda. Data ini semata-mata digunakan untuk pengembangan kontrak dan komunikasi dengan Anda dan Layanan kami yang diberikan kepada Anda. Hal ini ditunjukkan untuk menyediakan, memelihara, melindungi dan memperbaiki Layanan yang Anda gunakan. Pengguna yang terdaftar juga mengirim email pemberitahuan tentang aktivasi Layanan.
{"\n"}{"\n"}{"\n"}
</Text>

<Text style={[termsAndConditionStyles.title]}>
Bagaimana kami menjamin data Anda?
</Text>

<Text style={termsAndConditionStyles.text}>
{"\n"}
Anda, sebagai orang yang bertanggung jawab atas Data Pengguna yang kami proses atas nama Anda. Di sisi lain kami sebagai Layanan menjamin Anda bahwa
{"\n"}{"\n"}
1. Fammi tidak pernah menjual atau membagikan data kontak pengguna (email, no telepon) kepada siapapun.{"\n"}
2. Narasumber atau Ahli berhak atas data pengguna di atas untuk kepentingan yang berkaitan dengan layanan yang diberikan di dalam aplikasi. Dalam hal ini Fammi bertanggung jawab penuh atas keamanan data pengguna.
{"\n"}{"\n"}
Jika terjadi penyalahgunaan data, pengguna berhak melaporkan penyalahgunaan data oleh narasumber kepada fammi dan pihak fammi akan melakukan:
{"\n"}{"\n"}
1. Konfirmasi atas kejadian tersebut.{"\n"}
2. Memberikan teguran kepada fammi jika terbukti hal yg dilaporkan ternyata benar.{"\n"}
3. Menggunakan jalur hukum jika terdapat kerugian di pihak pengguna atas penyalahgunaan yg dilakukan oleh fammi.{"\n"}
{"\n"}{"\n"}
Pengguna selama penggunaan dan penyediaan Layanan{"\n"}
Kami percaya bahwa keamanan data pengguna sangatlah berharga. Semua data yang tersimpan dalam Layanan dan kami jaga kerahasiaan dan keamanannya. Namun, tidak ada sistem keamanan yang benar-benar terjamin 100% aman dari gangguan. Jika terjadi gangguan akibat pelanggaran keamanan, kami akan mengambil langkah untuk menyelidiki situasi tersebut. Selain itu, kami akan memberitahu pengguna Layanan dan mengambil langkah sesuai dengan hukum dan peraturan yang berlaku.
{"\n"}{"\n"}{"\n"}
</Text>

<Text style={[termsAndConditionStyles.title]}>
Bagaimana Layanan Kami Melakukan Perlindungan?
</Text>

<Text style={termsAndConditionStyles.text}>
{"\n"}
Layanan Kami dapat mengumpulkan, mengungkapkan dan menyimpan informasi pengguna seperti yang dijelaskan dalam Kebijakan Data ini. Kami tidak dapat menggunakan atau berbagi informasi untuk kepentingan iklan ataupun komersial. Kami hanya akan mengizinkan pihak ketiga dalam berbagi informasi hanya untuk kepentingan perlindungan hukum jika dibutuhkan sesuai dengan peraturan yang berlaku sesuai dengan yang dijelaskan di Kebijakan Data ini.
{"\n"}{"\n"}{"\n"}
</Text>

<Text style={[termsAndConditionStyles.title]}>
Bagaimana Anda memberikan Persetujuan?
</Text>

<Text style={termsAndConditionStyles.text}>
{"\n"}
Dengan melengkapi formulir yang disediakan Kami pada saat pendaftaran Akun, Anda menyatakan telah membaca dan menyetujui persyaratan Ketentuan Layanan dan Kebijakan Data ini. Tanpa mengurangi hal yang telah disebutkan diatas, Anda secara tegas telah menyetujui:
{"\n"}{"\n"}
1. Pengumpulan dan pengolahan data pribadi Anda oleh Layanan Kami yang telah dijelaskan pada Kebijakan Data ini.{"\n"}
2. Persetujuan Anda terhadap segala pengumpulan dan pemprosesan data yang Layanan lakukan.
{"\n"}{"\n"}
Persetujuan dinyatakan sah ketika Anda mensubmit data diri Anda di pendaftaran.
{"\n"}{"\n"}{"\n"}
</Text>

<Text style={[termsAndConditionStyles.title]}>
Hak Pengguna
</Text>

<Text style={termsAndConditionStyles.text}>
{"\n"}
Anda memiliki hak untuk mendaftar, menggunakan, memperbaiki, menghapus, memblokir dan mengolah data pribadi Anda. Selain itu, Anda dapat menarik kembali persetujuan Anda untuk memproses data dan informasi pribadi Anda. Hal ini tidak akan merusak Layanan kami hanya saja dengan begitu Anda telah mengundurkan diri dari penggunaan Layanan kami. Kami akan membantu Anda sesuai dengan fungsi Layanan dan Jangka waktu perjanjian untuk melakukan proses data pribadi. Anda juga dapat memilih untuk tidak memberikan data tertentu kepada Kami, tetapi mungkin tidak dapat menggunakan fitur tertentu dari layanan Kami. Silahkan hubungi Kami di alamat care@fammi.ly untuk melakukan penghapusan data
{"\n"}{"\n"}{"\n"}
</Text>

<Text style={[termsAndConditionStyles.title]}>
Bagaimana jika terdapat perubahan kebijakan data?
</Text>

<Text style={termsAndConditionStyles.text}>
{"\n"}
Kami dapat mengubah Kebijakan Data ini sewaktu-waktu dengan berbagai alasan, termasuk untuk meningkatkan keamanan data kami, perubahan pada Layanan kami dan mematuhi aturan hukum yang berlaku. Perubahan isi kebijakan ini akan diinformasikan kepada pengguna melalui email ataupun Layanan Fammi. Jika terdapat perubahan, kami mendorong pengguna untuk meninjau secara cermat, jika pengguna setuju dengan perubahan tersebut silahkan teruskan menggunakan Layanan kami. Namun jika pengguna tidak setuju dengan perubahan yang kami lakukan dan pengguna tidak ingin menggunakan Layanan kami, pengguna dapat memilih menutup Akun. Ketika melakukan perubahan Kebijakan Data, kami akan mengubah tanggal pembaharuan di halaman ini.
{"\n"}{"\n"}{"\n"}
</Text>

<Text style={[termsAndConditionStyles.title]}>
Bagaimana cara menghubungi Kami ?
</Text>

<Text style={termsAndConditionStyles.text}>
{"\n"}
Jika ada pertanyaan apa pun terkait dengan Kebijakan Data ini, silahkan hubungi Kami dengan mengirimkan surat elektronik kepada Kami di care@fammi.ly
</Text>
        </View>
      </ScrollView>
    </>
  );
};

export default Condition;
