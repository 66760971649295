import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { AppBar, Snackbar } from "../components";
import { CheckIcon, ChevronDown } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import Modal from "react-native-modal";
import md5 from "md5";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setConsultations } from "../services/consultations";
import moment from "moment";
import { bookSchedule, checkSchedule } from "../services/schedules";

function ConsultationForm({route}) {
  const navigation = useNavigation();
  const [consultationData, setConsultationData] = useState({});
  const [visible, setVisible] = useState(false);
  const [pilihan, setPilihan] = useState([]);
  const {speakerId, consultationDate, consultationHour, consultationType} = route.params;
  const [key, setKey] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("Jadwal sudah penuh. Silahkan pilih jadwal lain.");
  const { t } = useTranslation();

  const target = t("consultation_form:popup.about.list", { returnObjects: true });
  const problems = t("consultation_form:popup.problems.list", { returnObjects: true });

  const targetKonsultasi = target;
  const problemCategories = problems;

  return (
    <Fragment>
      <AppBar title={t("consultation_form:appbar_title")} isBack />
      <ScrollView style={styles.container}>
        <View style={{ marginBottom: 16 }}>
          <Text style={styles.txLabel}>{t("consultation_form:fields.name_label")}</Text>
          <TextInput
            style={styles.inputStyle}
            placeholder={t("consultation_form:fields.name_label")}
            placeholderTextColor="#999999"
            onChangeText={(text) => {
              setConsultationData({
                ...consultationData, 
                "summary": text
              });
            }}
            onChange={(e) => {
              setConsultationData({
                ...consultationData, 
                "summary": e.target.value
              });
            }}
          />
        </View>
        <View style={{ marginBottom: 16 }}>
          <Text style={styles.txLabel}>{t("consultation_form:fields.about_label")}</Text>
          <TouchableOpacity style={styles.inputStyle}
            onPress={() => {
              setPilihan(targetKonsultasi);
              setKey("tentang");
              setVisible(true);
            }}
          >
            <Text style={{
              ...styles.txSpan,
              color: consultationData['tentang'] ? '#000' : "#999"
            }}>
              {
                consultationData['tentang'] ? consultationData['tentang'] : t("consultation_form:popup.about.title")
              }
            </Text>
            <ChevronDown />
          </TouchableOpacity>
          <View style={styles.flexed}>
            <TextInput
              style={{ ...styles.inputStyle, width: "47.5%" }}
              placeholder={t("consultation_form:fields.about_age_placeholder")}
              placeholderTextColor={"#999999"}
              onChange={(e) => {
                setConsultationData({
                  ...consultationData, 
                  "usia": e.target.value
                });
              }}
              onChangeText={(text) => {
                setConsultationData({
                  ...consultationData, 
                  "usia": text
                });
              }}
            />
            <TouchableOpacity
              style={{
                ...styles.inputStyle,
                width: "24.5%",
                justifyContent: "flex-start",
                backgroundColor: consultationData['gender'] == "pria" ? "#6724E2" : "#FFF",
              }}
              onPress={() => {
                setConsultationData({
                  ...consultationData, 
                  "gender": "pria"
                });
              }}
            >
              <CheckIcon fill={consultationData['gender'] == "pria" ? "#FFF" : "#000"} />
              <Text style={{ ...styles.txLabel, marginLeft: 12, color: consultationData['gender'] == "pria" ? "#FFF" : "#000" }}>L</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                ...styles.inputStyle,
                width: "24.5%",
                justifyContent: "flex-start",
                backgroundColor: consultationData['gender'] == "wanita" ? "#6724E2" : "#FFF",
              }}
              onPress={() => {
                setConsultationData({
                  ...consultationData, 
                  "gender": "wanita"
                });
              }}
            >
              <CheckIcon fill={consultationData['gender'] == "wanita" ? "#FFF" : "#000"} />
              <Text style={{ ...styles.txLabel, marginLeft: 12, color: consultationData['gender'] == "wanita" ? "#FFF" : "#000" }}>P</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ marginBottom: 16 }}>
          <Text style={styles.txLabel}>{t("consultation_form:fields.problems_label")}</Text>
          <TextInput
            style={styles.inputStyle}
            placeholder={t("consultation_form:fields.problems_placeholder")}
            placeholderTextColor={"#999999"}
            multiline={true}
            numberOfLines={4}
            onChange={(e) => {
              setConsultationData({
                ...consultationData, 
                "question": e.target.value
              });
            }}
            onChangeText={(text) => {
              setConsultationData({
                ...consultationData, 
                "question": text
              });
            }}
          />
        </View>
        <View style={{ marginBottom: 16 }}>
          <Text style={styles.txLabel}>{t("consultation_form:fields.category_label")}</Text>
          <TouchableOpacity style={styles.inputStyle}
            onPress={() => {
              setPilihan(problemCategories);
              setKey("categories");
              setVisible(true);
            }}
          >
            <Text style={{
              ...styles.txSpan,
              color: consultationData['categories'] ? '#000' : '#999'
            }}>
              {
                consultationData['categories'] ? consultationData['categories'] : t("consultation_form:popup.problems.title")
              }
            </Text>
            <ChevronDown />
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={async () => {
            // checkSchedule(speakerId, moment(consultationDate).format("YYYY-MM-DD")+" "+consultationHour.split("-")[0])
            // .then(async (res) => {
              // if (res?.data !== false) {
              //   setSnackBarMsg("Maaf, jadwal sudah dibook. Silahkan pilih jadwal lain.")
              //   return setOpenSnackBar(true);
              // }

              const payload = {
                "consId": md5(new Date()),
                "created": null,
                "memberId": await AsyncStorage.getItem("token"), 
                "summary": consultationData.summary, 
                "question": consultationData.question , 
                "profile": JSON.stringify({
                  "usia": consultationData.usia,
                  "gender": consultationData.gender,
                  "tentang": consultationData.tentang
                }), 
                "categories": consultationData.categories,
                "transactionId": await AsyncStorage.getItem("0b806ea"),
                "speakerId": speakerId, 
                "consultationHour": consultationHour, 
                "consultationDate": moment(consultationDate).format("YYYY-MM-DD"), 
                "consultationType": consultationType
              };

              bookSchedule({
                bookId: md5(new Date().getTime()),
                bookTime: moment(consultationDate).format("YYYY-MM-DD")+ " " +consultationHour.split("-")[0],
                speakerId: speakerId,
                memberId: await AsyncStorage.getItem("token"),
                details: "",
                bookEndTime: moment(consultationDate).format("YYYY-MM-DD")+ " " +consultationHour.split("-")[1],
                transactionId: await AsyncStorage.getItem("0b806ea")
              });
  
              setConsultations(payload)
              .then(async () => {
                navigation.navigate("DetailOrder", {
                  transactionId: await AsyncStorage.getItem("0b806ea")
                });
              })
            // });
          }}
          style={styles.btnConsul}
        >
          <Text style={styles.txConsul}>{t("consultation_form:cta_label")}</Text>
        </TouchableOpacity>
        <View style={{ marginBottom: 16 }} />
      </ScrollView>

      <Modal 
        isVisible={visible} 
        onBackdropPress={() => {
          setVisible(false);
        }}
      >
        <View style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: 10
        }}>
          <View style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  paddingLeft: 12,
                  paddingRight: 12,
                }}>
            <Text>{key === "categories" ? t("consultation_form:popup.problems.title") : t("consultation_form:popup.about.title")}</Text>
          </View>
          {
            pilihan.map((p, i) => {
              return <TouchableOpacity
                onPress={() => {
                  setConsultationData({
                    ...consultationData, 
                    [key]: p
                  })
                  setVisible(false);
                }}
              >
                <View style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  paddingLeft: 12,
                  paddingRight: 12,
                  borderBottom: i === pilihan.length - 1 ? "none" : "solid 1px #EAEAEA"
                }}>
                  <Text>{p}</Text>
                </View>
              </TouchableOpacity>
            })
          }
        </View>
      </Modal>
      <Snackbar
        visible={openSnackBar}
        hideDialog={() => {
          setOpenSnackBar(false);
        }}
        title={snackBarMsg}
      />
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: "#EEEAFF",
  },
  txLabel: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
  },
  inputStyle: {
    textAlignVertical: 'top',
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 12,
    paddingRight: 12,
    paddingBottom: 12,
    paddingLeft: 12,
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    marginTop: 10,
  },
  txSpan: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    color: "#999999",
  },
  flexed: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnConsul: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    height: 36,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  txConsul: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
  },
});
export default ConsultationForm;
