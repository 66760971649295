import React from "react";
import { Image, ScrollView, View, useWindowDimensions } from "react-native";
import CreateResponsiveStyle from "../Utils/createResponsiveStyle";
import {
  Care,
  Download,
  Footer,
  Hero,
  Mission,
} from "../components/landing-page";

function LandingPage() {
  const layout = useWindowDimensions();
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);
  return (
    <ScrollView contentContainerStyle={styles("container")}>
      <View style={styles("subContainer")}>
        <View style={{ gap: isMobile ? 50 : 80, flex: 1 }}>
          <Hero layout={layout} />
          <Image
            source={require(`../assets/images/brands${
              isMobile ? "-mob" : ""
            }.png`)}
            alt="brands-image"
            style={styles("image")}
          />
          <Care layout={layout} />
          <Mission layout={layout} />
          <Download layout={layout} />
          <Footer layout={layout} />
        </View>
      </View>
    </ScrollView>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      paddingTop: 80,
      paddingHorizontal: 100,
      backgroundColor: "#fff",
      display: "flex",
      alignItems: "center",
    },
    subContainer: {
      maxWidth: 1366,
      display: "flex",
      alignItems: "center",
      justifyItem: "center",
    },
    image: {
      width: "100%",
      height: 40,
    },
  },
  {
    container: {
      paddingTop: 4,
      paddingHorizontal: 4,
    },
    image: {
      width: 298,
      height: 183,
      alignSelf: "center",
    },
  }
);

export default LandingPage;
