import axios from "axios";
import {API_URL} from "@env";

export const getMemberAgenda = (mode='member', memberId) => {
    if (mode === "member") {
        return axios.get(API_URL + `/get-agenda/${memberId}`);
    }
}

export const getAgendas = (mode, memberId) => {
    return axios.get(API_URL + `/get-all-agenda/${mode}/${memberId}`);
}

export const getSpesificAgenda = (consId, memberId) => {
    return axios.get(API_URL + `/get-spesific-agenda/${consId}/${memberId}`);
}