import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Platform,
} from "react-native";
import Modal from "react-native-modal";
import {
  ChevronRight,
  CloseIcon,
  MoneyIcon,
  TicketIcon,
  WalletIcon,
} from "../assets/icons";
import { SvgUri } from "react-native-svg";
import { useTranslation } from "react-i18next";

function ModalPaymentMethod(props) {
  const [credit, setCredit] = useState({});
  const { visible, hideDialog, setMethod, credits } = props;
  const isAndroid = Platform.OS === "android";
  const {t} = useTranslation();
  useEffect(() => {
    if (!credits) {
      return;
    }

    let creditObj = {};
    credits?.map((c) => {
      creditObj[c?.type+'-'+c?.status] = c.total;
    });
    setCredit(creditObj);
  }, [credits]);

  const LISTMETHOD = [
    {
      title: "E-Wallet",
      icons: <WalletIcon customColor="#000" />,
      methods: [
        {
          title: "Shopee Pay",
          id: "shopeepay",
          img: "https://fammi.ly/assets/images/shopeepay.svg",
        },
        {
          title: "GoPay",
          id: "gopay",
          img: "https://fammi.ly/assets/images/gopay.svg",
        },
      ],
    },
    {
      title: "Virtual Account",
      icons: <MoneyIcon />,
      methods: [
        {
          title: "Mandiri",
          id: "echannel",
          img: "https://fammi.ly/assets/images/bank-mandiri-icon.svg",
        },
        {
          title: "BNI",
          id: "bni",
          img: "https://fammi.ly/assets/images/bank-bni-icon.svg",
        },
        {
          title: "BRI",
          id: "bri",
          img: "https://fammi.ly/assets/images/bank-bri-icon.svg",
        },
        {
          title: "Permata",
          id: "permata",
          img: "https://fammi.ly/assets/images/bank-permata-icon.svg",
        },
      ],
    },
    {
      title: "Kuota",
      icons: <TicketIcon />,
      methods: [
        {
          title: credits?.length > 0 && credit?.['11-general'] ? `${t("the-type:consultation")} ${credit['11-general']}x, ${t("the-type:sharing")} ${credit['9-general']}x, ${t("the-type:confide")} ${credit['10-general']}x` : t("the-type:empty-kuota"),
          id: "fammi-kuota",
          img: "https://fammi.ly/images/fammi-logo.svg",
        },
      ],
    },
  ];
  const onChooseMethod = (method) => {
    setMethod(method);
    hideDialog();
  };
  return (
    <Modal isVisible={visible} onBackdropPress={hideDialog}>
      <View style={styles.parentBox}>
        {LISTMETHOD.map((item, index) => (
          <View key={index}>
            <View
              style={{
                ...styles.flexOne,
                justifyContent: "space-between",
                marginBottom: 12,
              }}
            >
              <View style={styles.flexOne}>
                {item.icons}
                <Text style={styles.txTitle}>{item.title}</Text>
              </View>
              {index === 0 && (
                <TouchableOpacity onPress={hideDialog}>
                  <CloseIcon />
                </TouchableOpacity>
              )}
            </View>
            {item.methods.map((method, idx) => (
              <TouchableOpacity
                key={idx}
                onPress={() => onChooseMethod(method)}
                style={{
                  ...styles.flexOne,
                  marginBottom: 12,
                  justifyContent: "space-between",
                }}
              >
                <View style={styles.flexOne}>
                  <View style={styles.parentImg}>
                    {isAndroid ? (
                      <SvgUri uri={method.img} height={16} width={40} />
                    ) : (
                      <img src={method.img} height={16} width={40} />
                    )}
                  </View>
                  <Text style={styles.txMain}>{method.title}</Text>
                </View>
                <ChevronRight />
              </TouchableOpacity>
            ))}
          </View>
        ))}
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    paddingTop: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16,
  },
  inputText: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#DEDEDE",
    borderStyle: "solid",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    paddingTop: 12,
    paddingRight: 12,
    paddingBottom: 12,
    paddingLeft: 12,
    textAlign: "center",
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
  },
  parentImg: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#DEDEDE",
    borderStyle: "solid",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    height: 36,
    width: 78,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  btnVoucher: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    height: 36,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 16,
  },
  txVoucher: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
    marginLeft: 6,
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center",
  },

  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    marginLeft: 6,
  },
  txMain: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    marginLeft: 12,
  },
});

export default ModalPaymentMethod;
