import React, { useEffect, useState } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import {
  EmojiHappyIcon,
  HeartTickIcon,
  MessengerFillIcon,
} from "../assets/icons";
import {
  AppBar,
  ListExpert,
  ModalQuotaOver,
  ProgramServiceFilter,
} from "../components";
import ChooseExpert from "./ChooseExpert";
import { getMemberCredits } from "../services/member_credits";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";

const ProgramDetail = ({route}) => {
  const [serviceFilter, setServiceFilter] = useState("all");
  const [quotaOver, setQuotaOver] = useState(false);
  const {params} = route;
  const [member, setMember] = useState(undefined);
  const [credit, setCredit] = useState([]);
  const {t} = useTranslation();
  const icons = {
    "9": HeartTickIcon, // figure
    "10": EmojiHappyIcon, // partner
    "11": MessengerFillIcon // expert
  };

  useEffect(() => {
    async function getMember() {
      const memberId = await AsyncStorage.getItem("token");
      setMember(memberId);
      return memberId;
    }

    getMember();
  }, []);

  useEffect(() => {
    if (!member) {
      return;
    }

    getMemberCredits(member)
    .then((res) => {
      const resp = res?.data;

      const creditData = [];
      resp.map((c) => {
        if (c.status !== `program-${params.program?.additionalContentId}`) {
          return;
        }

        creditData.push({
          type: c.type, 
          name: c.name, 
          total: c.total
        })
      });
      setCredit(creditData);
    })
  }, [member]);

  return (
    <React.Fragment>
      <AppBar title="Program" isBack />
      <View style={styles.container}>
        <View style={styles.headerBox}>
          <Image
            source={{ uri: `https://fammi.ly/uploads/products/images/${params.program?.productImage}` }}
            style={styles.image}
          />
          <Text style={styles.title}>{params.program.productName}</Text>
          {
            params.program.productDescription &&
            <Text style={styles.desc}>
              {params.program.productDescription}
            </Text>
          }
          <Text style={styles.title}>Kuota</Text>
          <View style={[styles.flexRow, { columnGap: 10 }]}>
            {
              credit.map((c, index) => {
                return <KuotaProgram
                  kuota={c.total}
                  Icon={icons[c.type]}
                  label={t("speaker-type-desc:"+c.name.toLowerCase().replace(/ /g, "-"))}
                />     
              })
            }
{/*             
            <KuotaProgram
              kuota={20}
              Icon={HeartTickIcon}
              label="Sharing ke Tokoh"
            />
            <KuotaProgram
              kuota={15}
              Icon={EmojiHappyIcon}
              label="Cerita ke Sahabat"
            /> */}
          </View>
        </View>
        <View>
          {/* <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={[
              styles.flexRow,
              { columnGap: 12, padding: 16 },
            ]}
          >
            {["all", "consultation", "parent", "friend"].map((f) => (
              <ProgramServiceFilter
                key={f}
                type={f}
                isActive={serviceFilter === f}
                onFilter={() => setServiceFilter(f)}
              />
            ))}
          </ScrollView> */}
          <ChooseExpert 
          route={{
            params: {
              type: "expert",
              status: `program-${params.program?.additionalContentId}`,
              hideHeader: true
            }
          }}/>
        </View>
        {/* <ScrollView contentContainerStyle={styles.services}>
          {[1, 2, 3, 4, 5].map((item, index) => (
            <TouchableOpacity onPress={() => setQuotaOver(true)}>
              <ListExpert key={index} />
            </TouchableOpacity>
          ))}
        </ScrollView> */}
      </View>
      <ModalQuotaOver visible={quotaOver} onClose={() => setQuotaOver(false)} />
    </React.Fragment>
  );
};

const KuotaProgram = ({ Icon, kuota, label }) => {
  return (
    <View style={styles.kuota}>
      <View style={styles.flexRow}>
        <Icon width={18} height={18} fill="#000" />
        <Text style={styles.txKuota}>{kuota.replace(" sesi", "")}x</Text>
      </View>
      <Text
        style={[styles.desc, { fontSize: 12, lineHeight: 13, marginTop: 6 }]}
      >
        {label}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#EEEAFF",
  },
  headerBox: {
    backgroundColor: "#fff",
    padding: 16,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  image: {
    width: "100%",
    height: 61,
    borderRadius: 10,
    resizeMode: "cover",
  },
  title: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 15,
    marginTop: 16,
    marginBottom: 10,
  },
  desc: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 13,
    lineHeight: 16,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  kuota: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#eaeaea",
    padding: 10,
    flex: 1,
  },
  txKuota: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 15,
    marginLeft: 6,
  },
  services: {
    paddingHorizontal: 16,
    paddingBottom: 30,
    flex: 1,
  },
});

export default ProgramDetail;
