import * as React from "react";
import Svg, { Circle, G, Mask, Path } from "react-native-svg";
const IndonesianFlag = (props) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<Circle cx="11" cy="11" r="10.5" stroke="#EAEAEA"/>
<Mask id="mask0_249_30"  maskUnits="userSpaceOnUse" x="1" y="1" width="20" height="20">
<Circle cx="11" cy="11" r="10" fill="#D9D9D9"/>
</Mask>
<G mask="url(#mask0_249_30)">
<Path d="M-1.27517 1.0558H23.2745C24.105 1.0558 24.7861 1.76788 24.7861 2.62856V19.3716C24.7861 20.2323 24.1109 20.9382 23.2805 20.9443H-1.28712C-1.68689 20.939 -2.06866 20.7712 -2.35024 20.477C-2.63183 20.1829 -2.79072 19.7859 -2.79272 19.3716V2.62856C-2.79272 1.76788 -2.10564 1.0558 -1.27517 1.0558Z" fill="white" stroke="#CCCCCC" stroke-width="0.19" stroke-miterlimit="2.613"/>
<Path d="M-1.22049 1.11097H23.2216C24.082 1.0862 24.7332 1.79208 24.7332 2.68372V10.9995H-2.73804V2.68372C-2.73804 1.79208 -2.0868 1.0862 -1.22049 1.11097Z" fill="#FF0000"/>
</G>
</Svg>


);
export default IndonesianFlag;
