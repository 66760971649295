import { Image, Pressable, Text, View, TextInput, StyleSheet, ImageBackground, StatusBar, Platform, Switch, Button, TouchableOpacity, ScrollView } from "react-native";
import { sendOtp } from "../services/login";
import { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Fammi from "../assets/icons/Fammi";
import IndonesianFlag from "../assets/icons/IndonesianFlag";
import messaging from '@react-native-firebase/messaging';
import { useTranslation } from "react-i18next";
import { getSpesificAgenda } from "../services/agenda";
import moment from "moment";
import i18n from "../translation/index";

function Login() {
  const [phone, setPhone] = useState("");
  const navigation = useNavigation();
  const { t } = useTranslation();
  const [userMode, setUserMode] = useState({});
  const [notif, setNotif] = useState(-1);
  const [lang, setLang] = useState();

  useEffect(() => {
    if (notif < 0) {
      return;
    }
    
    if (userMode.memberId && notif > 0) {
      return navigation.navigate("Home");
    }
  }, [notif, userMode]);

  useEffect(() => {
    if (Platform.OS !== "web") {
      messaging().onNotificationOpenedApp(remoteMessage => {
        if (!userMode?.memberId) {
          return;
        }

        setNotif(0);

        if (!remoteMessage?.data?.consId) {
          return navigation.navigate("Chat");
        }

        if (remoteMessage?.data?.consId) {
          getSpesificAgenda(remoteMessage?.data?.consId, userMode.memberId)
          .then(async (res) => {

            const resp = res?.data;
            const clientChat = [];
            let speakerId = "";
            if (userMode.mode === "speaker") {
              speakerId = await AsyncStorage.getItem("792f3e1");
            }

            resp.map((c) => {
              const consultationTime = moment(c.consultationDate+' '+c.consultationHour.split("-")[0]);
              const consultationEnd = moment(c.consultationDate+' '+c.consultationHour.split("-")[1]);
              clientChat.push({
                title: c.summary,
                name: c.memberId === userMode.memberId ? c.speakerName : c.memberName,
                time: consultationTime.format("DD MMM"),
                timeUnformatted: consultationEnd,
                isRead: true,
                isLike: true,
                mode: userMode.mode,
                target: c.speakerId, 
                photo: c.memberId === userMode.memberId ? c.speakerImage : c.memberPhoto,
                isDone: consultationEnd < moment(),
                consultationReady: consultationTime <= moment(),
                consId: c.consId,
                feedbackId: c.feedbackId,
                consultationDate: c.consultationDate,
                consultationHour: c.consultationHour,
                speakerId: c.speakerId,
                memberId: c.memberId,
                loggedInMemberId: userMode.memberId,
                speakerMemberId: c.speakerMemberId,
              });
            });
            return navigation.navigate("DetailChat", clientChat[0]);  
          })
        }
      });
    }

    setTimeout(() => {
      if (notif === 0) {
        return;
      }

      setNotif(new Date().getTime());
    }, 1000);
  }, [userMode]);

  useEffect(() => {
    async function getUser() {
      let modes = {
        memberId: await AsyncStorage.getItem("token"),
        mode: await AsyncStorage.getItem("792f3e7")
      };

      if (modes.mode === "speaker") {
        const speakerId = await AsyncStorage.getItem("792f3e1");
        modes['speakerId'] = speakerId;
      }

      setUserMode(modes);
      return modes;
    }

    async function getLang() {
      return await AsyncStorage.getItem("lang");
    }

    getLang()
    .then((res) => {
      if (!res) {
        AsyncStorage.setItem("lang", "id");
        i18n.changeLanguage("id");
        return;
      }
      AsyncStorage.setItem("lang", res);
      i18n.changeLanguage(res);
    });
    

    getUser();
  }, []);
  return (
    <>
    <StatusBar backgroundColor={"#6724E2"}/>
    <ScrollView contentContainerStyle={{ flex: 1}}>
    <View
      style={{
        backgroundColor: "#EEEAFF",
        height: "100%",
      }}
    >
      <StatusBar backgroundColor={"#933FF4"}/>
      <View
        style={{
          height: 358,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
          overflow: "hidden"
        }}
      >
        <ImageBackground source={require("../assets/images/loginbg.png")}  resizeMode="cover" style={{flex: 1, justifyContent: "center", width: "100%", height: 358, borderBottomRightRadius: 10, borderBottomLeftRadius: 10}}>
          <View style={{opacity: 1.0, backgroundColor: "#933FF4", height: 620, opacity: 0.9, position: "absolute", top: 0, height: 358, width: "100%"}}>
          </View>
        </ImageBackground>
        <View style={{padding: 24}}>
            {/* <Image
              source={require("../assets/fammi-logo-white.svg")}
              style={{ width: 109, height: 36, marginTop: 80 }}
            /> */}
            <Fammi/>
            <Text style={{ fontSize: 24, color: "#FFF", marginTop: 24, fontFamily: "Archivo-Bold" }}>
              Social Community Based Learning Hub to Support Families Challenges
            </Text>
            <Text style={{ fontSize: 14, color: "#FFF", marginTop: 24, fontFamily: "Archivo-Regular", fontWeight: "500", lineHeight: 20 }}>
              {t("login:login-desc")}
              {/* Support system kamu untuk sharing & diskusi, belajar dan bertanya
              seputar permasalahan keluarga langsung dari para Ahli dan Orangtua
              Berpengalaman. */}
            </Text>
          </View>
      </View>
      <View
        style={{
          padding: 24,
        }}
      >
        <View
          style={{
            backgroundColor: "#FFF",
            height: 36,
            flexDirection: "row",
            borderRadius: 10,
          }}
        >
          <View
            style={{
              fontSize: 12,
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
              padding: 8
            }}
          >
            <IndonesianFlag/>
            <Text style={{
              fontFamily: "Archivo-SemiBold", 
              fontWeight: "500", 
              fontSize: 12
            }}>ID +62</Text>
            <View
              style={{
                backgroundColor: "#EAEAEA",
                height: 26,
                width: 2,
                margin: 8,
              }}
            >
              <Text>&nbsp;</Text>
            </View>
          </View>
          <TextInput
            style={{
              height: 36,
              fontSize: 12,
              borderWidth: 0,
              width: "100%"
            }}
            placeholder={t("login:enter-wa")}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            onChangeText={(text) => {
              setPhone(text);
            }}
          />
        </View>
        <View
          style={{
            marginTop: 12,
          }}
        >
          <Pressable
            style={{
              backgroundColor: "#6724E2",
              color: "#FFF",
              alignItems: "center",
              padding: 12,
              borderRadius: 10,
            }}
            onPress={() => {
              sendOtp(phone)
              .then((res) => {
                if (res.data === true) {
                  navigation.navigate("Verification", {
                    phone: phone
                  });
                }
              })
            }}
          >
            <Text
              style={{
                color: "#FFF",
                fontFamily: "Archivo-Bold"
              }}
            >
              {t("login:start")}
            </Text>
          </Pressable>
        </View>
        <View
          style={{
            marginTop: 24,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontFamily: "Archivo-Regular",
              fontWeight: "500",
            }}
          >
            {t("login:by-entering")}&nbsp;
            <Pressable
              onPress={() => navigation.navigate("Terms")}
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: 16,
              }}
            >
              <Text style={{...styles.linkText, ...styles.terms}}>{t("login:term-use")}</Text>
            </Pressable>
             <Text>&nbsp;&&nbsp;{" "}</Text>
             <Pressable
              onPress={() => navigation.navigate("Condition")}
              style={{
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "center",
                height: 16,
              }}
            >
              <Text style={{...styles.linkText, ...styles.terms}}>{t("login:policy")}</Text>
             </Pressable>
          </Text>
        </View>
      </View>
      <ScrollView contentContainerStyle={{
        flex: 1
      }}>
      <View style={{
        flexDirection: "row",
        position:"absolute",
        bottom: 24,
        width: "100%",
        justifyContent: "center"
      }}>
        <View style={{position:"absolute", top: 0, backgroundColor: "#FFF", left: "35%", width: 100, height: "100%"}}>
          <Text>
            &nbsp;
          </Text>
        </View>
        <TouchableOpacity style={{
          padding: 9,
          paddingLeft: 16,
          paddingRight: 16,
          backgroundColor: "#FFF",
          borderRadius: 16,
          borderWidth: lang === "id" ? 1: 0,
          borderColor: lang === "id" ? "#6724E2" : "#FFF"
        }}
          onPress={() => {
            setLang("id");
            AsyncStorage.setItem("lang", "id");
            i18n.changeLanguage("id");
          }}
        >
          <Text style={{
            fontFamily: lang === "id" ? "Archivo-SemiBold" : "Archivo-Regular",
            fontSize: 12,
            color: lang === "id" ? "#6724E2" : "#000",
          }}>
            Bahasa
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={{
          padding: 9,
          paddingLeft: 16,
          paddingRight: 16,
          backgroundColor: "#FFF",
          borderRadius: 16,
          borderWidth: lang === "en" ? 1: 0,
          borderColor: lang === "en" ? "#6724E2" : "#FFF"
        }}
        onPress={() => {
          setLang("en");
          AsyncStorage.setItem("lang", "en");
          i18n.changeLanguage("en");
        }}
        >
          <Text style={{
            fontFamily: lang === "en" ? "Archivo-SemiBold" : "Archivo-Regular",
            fontSize: 12,
            color: lang === "en" ? "#6724E2" : "#000",
          }}>
            English
          </Text>
        </TouchableOpacity>
      </View>
      </ScrollView>
    </View>
    </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  terms: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 16,
    textAlign: "center",
  },
  linkText: {
    color: "#6724E2",
    textDecorationLine: "underline",
  },
});

export default Login;
